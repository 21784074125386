import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow, notificationsSuccessShow } from './notifications';

// CONSTANTS
export const CURRENCIES_GET = 'CURRENCIES_GET';
export const CURRENCIES_SET = 'CURRENCIES_SET';
export const CURRENCIES_ERROR = 'CURRENCIES_ERROR';

export const APPROVE_TOKEN_OPEN_MODAL = 'APPROVE_TOKEN_OPEN_MODAL';
export const APPROVE_TOKEN_CLOSE_MODAL = 'APPROVE_TOKEN_CLOSE_MODAL';

// ACTIONS GENERATORS
export const currenciesGet = () => ({
  type: CURRENCIES_GET,
  fetching: true,
});

export const currenciesSet = data => ({
  type: CURRENCIES_SET,
  fetching: false,
  payload: data,
});

export const currenciesError = () => ({
  type: CURRENCIES_ERROR,
});

// ASYNC ACTIONS
export const getCurrencies = dispatch => async merchantId => {
  dispatch(currenciesGet());
  let currencies;

  try {
    currencies = await cryptoApi.getCurrencies(merchantId);
  } catch (err) {
    dispatch(currenciesError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (currencies && currencies.data) {
    dispatch(currenciesSet({ ...currencies.data }));
    return;
  }

  dispatch(currenciesError());
};

export const CREATE_CURRENCY_REQUEST = 'CREATE_CURRENCY_REQUEST';
export const CREATE_CURRENCY_SUCCESS = 'CREATE_CURRENCY_SUCCESS';
export const CREATE_CURRENCY_FAIL = 'CREATE_CURRENCY_FAIL';

const createCurrencyRequest = () => ({
  type: CREATE_CURRENCY_REQUEST
});

const createCurrencySuccess = payload => ({
  type: CREATE_CURRENCY_SUCCESS,
  payload
});

export const createCurrencyFail = payload => ({
  type: CREATE_CURRENCY_FAIL,
  payload
});

export const createCurrency = dispatch => async body => {
  dispatch(createCurrencyRequest());

  try {
    const response = await cryptoApi.createCurrency(body);
    dispatch(createCurrencySuccess(response?.data))
  } catch(error) {
    dispatch(createCurrencyFail(error?.data));
    dispatch(notificationsErrorShow(error.data));
  }
};

export const APPROVE_CURRENCY_REQUEST = 'APPROVE_CURRENCY_REQUEST';
export const APPROVE_CURRENCY_SUCCESS = 'APPROVE_CURRENCY_SUCCESS';
export const APPROVE_CURRENCY_FAIL = 'APPROVE_CURRENCY_FAIL';

const approveCurrencyRequest = () => ({
  type: APPROVE_CURRENCY_REQUEST
})

const approveCurrencySuccess = payload => ({
  type: APPROVE_CURRENCY_SUCCESS,
  payload
});

export const approveCurrencyFail = payload => ({
  type: APPROVE_CURRENCY_FAIL,
  payload
});

export const addTokenOpenModal = () => ({
  type: APPROVE_TOKEN_OPEN_MODAL,
});

export const addTokenCloseModal = () => ({
  type: APPROVE_TOKEN_CLOSE_MODAL,
});

export const approveCreateCurrency = dispatch => async requestId => {
  dispatch(approveCurrencyRequest());

  try {
    const response = await cryptoApi.approveCreateCurrency(requestId);
    dispatch(approveCurrencySuccess(response?.data))
    dispatch(notificationsSuccessShow('Token added successfully'));
  } catch (error) {
    dispatch(approveCurrencyFail(error?.data));
    dispatch(notificationsErrorShow(error.data));
  }
}

