import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Modal, Form, Input } from 'antd';
import { notificationsErrorShow } from '../../redux/actions/notifications';

import '../../layout/Modal.scss';
import './style.scss';
import { identity } from '../../utils/getIdentity';
import ResendPhone2FA from '../../ResendPhone2FA';
import ResendEmail2FA from '../ResendEmail2FA';

const SwapTwoFaConfirm = ({
  isVisible,
  setIsVisible,
  action,
  isAppTwoFa,
  isEmailTwoFa,
  isPhoneTwoFa,
  clearWithdrawState,
  fetching,
  body,
  resetTwoFa,
  notificationsErrorShow,
  setShowConfirmModal,
}) => {
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [emailTwoFactorCode, setEmailTwoFactorCode] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawConfirmForm] = Form.useForm();
  const { t } = useTranslation();

  console.log(isPhoneTwoFa)

  const handleSubmit = async () => {
    try {
      await withdrawConfirmForm.validateFields();

      setIsLoading(true);
      const response = await action({
        ...body,
        emailOtp: emailTwoFactorCode ? emailTwoFactorCode : undefined,
        twoFactorToken: twoFactorCode ? twoFactorCode : undefined,
        verifyCode: verifyCode ? verifyCode : undefined,
      });

      if (response && response.data.id) {
        resetTwoFa();
        setIsVisible(false);
        setShowConfirmModal(true);
      }

      if (response && response.status === 'fail') {
        if (isAppTwoFa && !twoFactorCode) {
          notificationsErrorShow({ message: t('validation.twoFaRequired') });
        }

        if (isEmailTwoFa && !emailTwoFactorCode) {
          notificationsErrorShow({ message: t('validation.emailTwoFaRequired') });
        }

        if (isPhoneTwoFa && !verifyCode) {
          notificationsErrorShow({ message: t('validation.phoneTwoFaRequired') });
        }
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = e => {
    setIsVisible(false);
    withdrawConfirmForm.resetFields();
    resetTwoFa();
    setTwoFactorCode();
    setEmailTwoFactorCode();
    setVerifyCode();
  };

  return (
    <React.Fragment>
      <Modal
        width={534}
        title={t('withdraw.enterTwoFa')}
        visible={isVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={null}
        className={`modal modal-${identity}`}
      >
        <div className="swap-twofa-confirm">
          <Form
            form={withdrawConfirmForm}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="basic"
            initialValues={{ remember: true }}
            className="modal-form"
          >
            {isPhoneTwoFa && !isEmailTwoFa && !isAppTwoFa && (
              <>
                <Form.Item
                  label={t('auth.сodeFromSms')}
                  name="verifyCode"
                  className="modal-form__label"
                  rules={[{ required: true, message: t('validation.phoneTwoFaRequired') }]}
                >
                  <Input
                    placeholder={t('auth.сodeFromSms')}
                    className="modal-form__input"
                    maxLength="6"
                    onChange={e => setVerifyCode(e.target.value)}
                  />
                </Form.Item>
                <ResendPhone2FA />
              </>
            )}
            {isAppTwoFa && (
              <Form.Item
                label={t('auth.keyFromGA')}
                name="authCode"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.twoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.keyFromGA')}
                  className="modal-form__input"
                  maxLength="6"
                  onChange={e => setTwoFactorCode(e.target.value)}
                />
              </Form.Item>
            )}
            {isEmailTwoFa && !isAppTwoFa && (
              <>
                <Form.Item
                  label={t('auth.keyFromEmail')}
                  name="emailCode"
                  className="modal-form__label"
                  rules={[{ required: true, message: t('validation.emailTwoFaRequired') }]}
                >
                  <Input
                    placeholder={t('auth.keyFromEmail')}
                    className="modal-form__input"
                    maxLength="6"
                    onChange={e => setEmailTwoFactorCode(e.target.value)}
                  />
                </Form.Item>
                <ResendEmail2FA />
              </>
            )}
          </Form>
          <Row className="modal__buttons-wrapper">
            <Col span="12">
              <Button
                loading={isLoading || fetching}
                type="primary"
                className={`modal__button modal__button-create modal__button modal__button-create-${identity} modal__button-create=${identity}`}
                onClick={handleSubmit}
              >
                {t('withdraw.confirm')}
              </Button>
            </Col>
            <Col span="12">
              <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
                {t('withdraw.cancel')}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isAppTwoFa: state.swap.twoFa.isAppTwoFa,
  isEmailTwoFa: state.swap.twoFa.isEmailTwoFa,
  isPhoneTwoFa: state.swap.twoFa.isPhoneTwoFa,
});

const mapDispatchToProps = dispatch => ({
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwapTwoFaConfirm);
