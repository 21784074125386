import React from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PageHeader from '../layout/PageHeader';
import MerchantWalletsList from '../components/WalletsPage/MerchantWalletsList';
import WalletCreateModal from '../components/WalletCreateModal/WalletCreateModal';

const MerchantWallets = ({
   networkFilter
}) => {
  const { merchantId } = useParams();
  const WalletCreateComponent = () => (
    <WalletCreateModal
      merchantId={merchantId}
      typeWallet="merchant"
      filtersFormName="merchantWalletsFilters"
      networkFilter={networkFilter}
    />
  );

  return (
    <React.Fragment>
      <PageHeader showBackButton={true} CreateModalComponent={WalletCreateComponent} />
      <div className="component">
        <MerchantWalletsList
          formName="merchantWalletsFilters"
          merchantId={merchantId}
          typeWallet="merchant"
          networkFilter={networkFilter}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  networkFilter: state.networkFilter
});

export default connect(mapStateToProps)(MerchantWallets);
