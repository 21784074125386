import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import './style.scss';
import { identity } from '../../../utils/getIdentity';

const OfferItemSwing = ({ offer, handleExchange, setRate, toId, currencies }) => {
  const { t } = useTranslation();
  const [currencyToName, setCurrencyToName] = useState('');

  const formatAmount = (amount, decimals) => {
    return Number(amount) / Math.pow(10, decimals);
  }

  useEffect(() => {
    if (currencies && currencies.length && toId) {
      const toCurrency = currencies.find(currency => currency._id === toId);
      setCurrencyToName(toCurrency);
    }
  }, [currencies, toId]);

  const handleConfirm = () => {
    handleExchange(offer?.route[0]?.bridge)
    setRate(formatAmount(offer?.quote?.amount, offer?.quote?.decimals))
  }

  return (
    <div className="offer-item">
      <div className="offer-item__row">
        <div className={`offer-item__column offer-item__column-${identity} offer-item__rate`}>
          <span className="offer-item__column-name">
            {'Bridge'}{' '}
            {offer?.route[0]?.bridge}
          </span>
        </div>
      </div>

      <div className="offer-item__row">
        <div className={`offer-item__column offer-item__column-${identity} offer-item__eta`}>
          <span className="offer-item__column-name">Amount</span>
          <span className="offer-item__column-value">{formatAmount(offer?.quote?.amount, offer?.quote?.decimals)}</span>
        </div>
      </div>

      <div className="offer-item__row">
        <div className={`offer-item__column offer-item__column-${identity} offer-item__eta`}>
          <span className="offer-item__column-name">Name</span>
          <span className="offer-item__column-value">{currencyToName?.name}</span>
        </div>
      </div>

      <div>
        <button
          type="button"
          className={`offer-item__exchange-button offer-item__exchange-button-${identity}`}
          onClick={handleConfirm}
        >
          {t('swap.offerItem.exchange')}
        </button>
      </div>
    </div>
  );
};

export default OfferItemSwing;
