import React, { useEffect, useState } from "react";
import './styles.scss';
import { useLocation } from "react-router-dom";
import { identity } from "../../../utils/getIdentity";
import emptyCpayImage from "../../../img/cpay-svg/empty.svg";
import emptyNFGImage from "../../../img/nfgpay-svg/empty-nfg.svg";
import emptyFinvaroImage from "../../../img/finvaro/empty-finvaro.svg";
import emptyClarniumImage from "../../../img/clarnium/empty-clarnium.svg";
import { ReactComponent as Copy } from "../../../img/default-svg/copyActivity.svg";
import { ReactComponent as External } from "../../../img/default-svg/externalLink.svg";
import moment from "moment";
import { Col, Input, Row, Pagination, Dropdown, Menu } from "antd";
import resetCpayIcon from "../../../img/cpay-svg/reset.svg";
import resetNfgIcon from "../../../img/nfgpay-svg/reset-nfg.svg";
import resetFinvaroIcon from "../../../img/finvaro/reset-finvaro.svg";
import resetClarniumIcon from "../../../img/clarnium/reset-clarnium.svg";
import { truncate } from "../../../utils/truncateFunction";
import CopyWrapper from "../../CopyWrapper";
import moreSVG from "../../../img/default-svg/more.svg";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import { cancelSubscriptionFail } from "../../../redux/actions/checkouts";
import { useDispatch } from "react-redux";

const checkoutsNoDataSVG = {
  cpay: emptyCpayImage,
  nfg: emptyNFGImage,
  finvaro: emptyFinvaroImage,
  clarnium: emptyClarniumImage
};

const resetIcon = {
  cpay: resetCpayIcon,
  nfg: resetNfgIcon,
  finvaro: resetFinvaroIcon,
  clarnium: resetClarniumIcon
};

const SubscriptionsList = ({
   merchantId,
   typeNetwork,
   getSubscriptions,
   subscriptions,
   cancelSubscription,
   isCanceled,
   cancelFetching
}) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const planId = urlParams.get('planId');
  const { page, pages, countItem, data } = subscriptions;
  const dispatch = useDispatch();

  const [search, setSearch] = useState(null);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [savedPage, setSavedPage] = useState(1);

  const handleGetSubscriptions = async (currentPage = 1) => {
    setSavedPage(currentPage);
    await getSubscriptions(merchantId, {
      page: currentPage,
      typeNetwork: typeNetwork,
      planId: planId,
      order: 'DESC',
      search: search
    });
  };

  const resetSearch = async () => {
    setSearch(null);
    await getSubscriptions(merchantId, {
      typeNetwork: typeNetwork,
      planId: planId,
      order: 'DESC',
      search: null
    });
  };

  const periodType = {
    0: 'Hourly',
    1: 'Daily',
    2: 'Weekly',
    3: 'Monthly',
    4: 'Yearly'
  };

  const handleCancel = () => {
    setIsOpenCancelModal(false);
    setSubscriptionId('');
  };

  const handleOpen = (id) => {
    setIsOpenCancelModal(true);
    setSubscriptionId(id);
  };

  const handleCancelSubscription = async () => {
    await cancelSubscription(subscriptionId);

    handleCancel();
    handleGetSubscriptions(savedPage);
  }

  useEffect(() => {
    if (merchantId && typeNetwork && planId) {
      handleGetSubscriptions();
    }
  }, [merchantId, typeNetwork, planId]);

  useEffect(() => {
    if (isCanceled) {
      dispatch(cancelSubscriptionFail());
    }
  }, [isCanceled])

  return (
    <div className="subscriptions">
      <div className="subscriptions__title">
        <div>Subscriptions</div>
        <div>
          <Input.Search
            name="search"
            placeholder="Search..."
            onSearch={() => handleGetSubscriptions()}
            className="checkouts-header__search-field"
            onChange={e => setSearch(e.target.value)}
            value={search}
            addonAfter={
              <span onClick={resetSearch} className="checkouts-header__reset-button">
                <img src={resetIcon[identity]} alt="Reset" />
              </span>
            }
          />
        </div>
      </div>
      {!data?.length ? (
        <div className="checkouts-list__empty">
          <div className="checkouts-list__empty-text">You have no active subscriptions</div>
          <img src={checkoutsNoDataSVG[identity]} alt="" />
        </div>
      ) : (
        <div className="activities__wrapper">
          <Row className="activities">
            <Col className="activities__titleCenter"  span={2}>
              Period Type
            </Col>
            <Col className="activities__titleCenter"  span={3}>
              Subscription owner
            </Col>
            <Col className="activities__titleCenter"  span={3}>
              Recurring amount
            </Col>
            <Col className="activities__titleCenter"  span={4}>
              Next Payment Time
            </Col>
            <Col className="activities__titleCenter"  span={4}>
              Last Transaction Hash
            </Col>
            <Col className="activities__titleCenter" span={1}>
              Name
            </Col>
            <Col className="activities__titleCenter" span={2}>
              Email
            </Col>
            <Col className="activities__titleCenter" span={2}>
              Click ID
            </Col>
            <Col className="activities__titleCenter" span={2}>
              Status
            </Col>
            <Col span={1}></Col>
          </Row>
          <div>
            {data?.map((item) => (
              <Row className="activities__rows">
                <Col className="activities__center" span={2}>
                  {periodType[item?.periodType] || '-'}
                </Col>
                <Col className="activities__center activities__center--hash" span={3}>
                  {item?.owner ? (
                    <div className="activities__hash">
                      <div className="activities__hash--link">{truncate(item?.owner, 12, '..')}</div>
                      <div className="activities__hash--icons">
                        <CopyWrapper copyContent={item?.owner}>
                          <Copy />
                        </CopyWrapper>
                      </div>
                    </div>
                  ) : '-'}
                </Col>
                <Col className="activities__center" span={3}>
                  {item?.amountRecurring || 0}
                  {' '}
                  {item?.currencyName}
                </Col>
                <Col className="activities__center" span={4}>
                  {moment(item?.nextPaymentTime).format('MM/DD/YYYY; h:mm A')}
                </Col>
                <Col className="activities__center activities__center--hash" span={4}>
                  {item?.lastHashTx ? (
                    <div className="activities__hash">
                      <div className="activities__hash--link">{truncate(item?.lastHashTx, 14, '..')}</div>
                      <div className="activities__hash--icons">
                        <CopyWrapper copyContent={item?.lastHashTx}>
                          <Copy />
                        </CopyWrapper>
                        <a href={item?.explorerUrl} target="_blank">
                          <External />
                        </a>
                      </div>
                    </div>
                  ) : '-'}
                </Col>
                <Col className="activities__center" span={1}>
                  <CopyWrapper copyContent={item?.customerName}>
                    <div className="activities__rows--padd">
                      {item?.customerName || '-'}
                    </div>
                  </CopyWrapper>
                </Col>
                <Col className="activities__center" span={2}>
                  <CopyWrapper copyContent={item?.customerEmail}>
                    <div className="activities__rows--padd">
                      {item?.customerEmail || "-"}
                    </div>
                  </CopyWrapper>
                </Col>
                <Col className="activities__center" span={2}>
                  <CopyWrapper copyContent={item?.customerOrderId}>
                    <div className="activities__rows--padd">
                      {item?.customerOrderId || "-"}
                    </div>
                  </CopyWrapper>
                </Col>
                <Col className={`activities__center ${item?.status ? 'activities__center--active' : 'activities__center--deactive'}`} span={2}>
                  {item?.status ? 'Active' : 'Deactive'}
                </Col>
                <Col className="activities__center" span={1}>
                  {item?.status && (
                    <Dropdown
                      placement="bottomLeft"
                      overlay={
                        <Menu>
                          <Menu.Item key="0" onClick={() => handleOpen(item?.subscriptionId)}>
                            Cancel Subscription
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <img style={{ cursor: 'pointer' }} src={moreSVG} alt="" />
                    </Dropdown>
                  )}
                </Col>
              </Row>
            ))}
          </div>
        </div>
      )}

      <div className="activities__pagination">
        {pages > 1 && (
          <Pagination
            current={page}
            total={countItem}
            pageSize={10}
            showTotal={false}
            showSizeChanger={false}
            size="small"
            onChange={page => handleGetSubscriptions(page)}
          />
        )}
      </div>

      <CancelSubscriptionModal
        visible={isOpenCancelModal}
        close={handleCancel}
        fetching={cancelFetching}
        handleCancelSubscription={handleCancelSubscription}
      />
    </div>
  )
};

export default SubscriptionsList;