import React, { useEffect,useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Form, Select } from "antd";
import Card from '../../components/Card';
import TEXT from '../../components/Text';
import { TextInput, PhoneInput } from '../../components/FormFields';
import { ButtonPrimary, ButtonSecondary } from '../../components/Button';
import Switcher from '../../components/Switcher';
import { getRolesList } from "../../../redux/actions/adminPanel/adminSettings";
import { getAdminUserById, updateAdminUser, resetAdminUserState } from '../../../redux/actions/adminPanel/adminUsers';
import {scrollContainerIntoView} from '../../helpers/scrollIntoView';
import './style.scss';
import { sanitizeInputValidator } from '../../../utils/sanitizeInput';

const UserEdit = ({
  userData,
  userDateFetching,
  getAdminUserById,
  updateAdminUser,
  resetAdminUserState,
  rolesList,
  getRolesList
}) => {
  const { userId } = useParams();
  const [form] = Form.useForm();
  const userEditRef = useRef();
  const [currentRole, setCurrentRole] = useState('');

  useEffect(() => () => resetAdminUserState(), []);

  useEffect(() => {
    if (userId) {
      getAdminUserById(userId);
      getRolesList();
    }
  }, [userId]);

  useEffect(() => {
    if (Object.keys(userData).length) {
      form.resetFields();
    }
  }, [userData]);

  useEffect(() => {
    if (userData && userData?.accessRole?.id) {
      setCurrentRole(userData?.accessRole?.id)
    }
  }, [userData]);

  const handleSubmit = (id, values) => {
    updateAdminUser(id, { ...values, accessRole: userData?.roles?.includes('admin') ? currentRole : undefined });
    setTimeout(() => {
      getAdminUserById(id);
    }, 500)
  }

  useEffect(() => {
    scrollContainerIntoView(userEditRef);
  }, []);

  return (
    <div className="user-edit" ref={userEditRef}>
      <Card>
        <TEXT.pageTitle>Edit</TEXT.pageTitle>
        <Form
          form={form}
          name="user-edit-form"
          initialValues={{
            name: userData.name,
            surname: userData.surname,
            email: userData.email,
            phone: userData.phone,
            isActive: userData.isActive,
            accessRole: currentRole
          }}
          onFinish={values => handleSubmit(userId, values)}
        >
          <div className="user-edit__section">
            <TEXT.sectionTitle>Personal info</TEXT.sectionTitle>
            <Row gutter="30" className="user-edit__inputs-wrap">
              <Col>
                <TextInput name="name" maxLength={true} label="First name" placeholder="First name" rules={[sanitizeInputValidator]} />
              </Col>
              <Col>
                <TextInput name="surname" maxLength={true} label="Last name" placeholder="Last name" rules={[sanitizeInputValidator]} />
              </Col>
            </Row>
          </div>
          <div className="user-edit__section">
            <TEXT.sectionTitle>Email and phone</TEXT.sectionTitle>
            <TEXT.sectionDescription className="system-fee-switcher__description">
              Your verified email address is used to sign in to your account and notify you when payments have been
              received. You may be asked for your password to change your email address.
            </TEXT.sectionDescription>
            <Row gutter="30" className="user-edit__inputs-wrap">
              <Col>
                <TextInput name="email" maxLength={false} label="Email" placeholder="Email" />
              </Col>
              <Col>
                <PhoneInput name="phone" label="Phone" />
              </Col>
            </Row>
          </div>
          <Switcher name="isActive" title="Active user" />
        </Form>
      </Card>
      <div className="user-edit-buttons">
        <Link to="/admin/users">
          <ButtonSecondary>Back</ButtonSecondary>
        </Link>
        <ButtonPrimary type="submit" onClick={form.submit} disabled={userDateFetching} loading={userDateFetching}>
          Save
        </ButtonPrimary>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userData: state.adminUsers.editableUser.data,
  userDateFetching: state.adminUsers.editableUser.fetching,
  rolesList: state.adminSettings.rolesList,
});

const mapDispatchToProps = {
  getAdminUserById,
  updateAdminUser,
  resetAdminUserState,
  getRolesList
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
