import { adminApi } from '../../../service/admin-api';
import { notificationsErrorShow } from '../notifications';
import moment from 'moment';
import Papa from 'papaparse';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const exportToExcel = (apiData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

// GET USERS EMAILS LIST
export const GET_ADMIN_USERS_EMAILS_REQUEST = 'GET_ADMIN_USERS_EMAILS_REQUEST';
export const GET_ADMIN_USERS_EMAILS_SUCCESS = 'GET_ADMIN_USERS_EMAILS_SUCCESS';
export const GET_ADMIN_USERS_EMAILS_ERROR = 'GET_ADMIN_USERS_EMAILS_ERROR';

export const getAdminUsersEmailsRequest = () => ({
  type: GET_ADMIN_USERS_EMAILS_REQUEST,
});

export const getAdminUsersEmailsSuccess = payload => ({
  type: GET_ADMIN_USERS_EMAILS_SUCCESS,
  payload,
});

export const getAdminUsersEmailsList = body => async dispatch => {
  dispatch(getAdminUsersEmailsRequest());

  const data =
    body === null
      ? { order: 'DESC' }
      : {
          ...body,
          dateOfSignUp: undefined,
          dateOfSignIn: undefined,
          search: body.search || undefined,
          emailConfirmed: body.emailConfirmed || undefined,
          fromSignUp:
            body.dateOfSignUp && body.dateOfSignUp[0]
              ? moment(body.dateOfSignUp[0]).format('YYYY-MM-DD') + 'T00:00:01'
              : undefined,
          toSignUp:
            body.dateOfSignUp && body.dateOfSignUp[1]
              ? moment(body.dateOfSignUp[1]).format('YYYY-MM-DD') + 'T23:59:59'
              : undefined,
          fromSignIn:
            body.dateOfSignIn && body.dateOfSignIn[0]
              ? moment(body.dateOfSignIn[0]).format('YYYY-MM-DD') + 'T00:00:01'
              : undefined,
          toSignIn:
            body.dateOfSignIn && body.dateOfSignIn[1]
              ? moment(body.dateOfSignIn[1]).format('YYYY-MM-DD') + 'T23:59:59'
              : undefined,
          sort: body.sort || 'createdAt',
          order: body.order || 'DESC',
        };

  try {
    const response = await adminApi.getUsersEmailsList(data);

    let newResponse;

    if (response) {
      newResponse = await adminApi.downloadExcelFile(response?.fileName)
    }

    Papa.parse(newResponse, {
      header: true,
      complete: results => {
        exportToExcel(results.data, 'users-emails');
        dispatch(getAdminUsersEmailsSuccess(response));
      },
    });
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};
