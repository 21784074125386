import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { showReleaseNotes } from '../../redux/actions/releaseNotes';
import { useTranslation } from 'react-i18next';
import releaseNotesArray from '../ReleaseNotes/releaseNotesArray';
import { identity } from '../../utils/getIdentity';

import './VersionOverlay.scss';
import { AppConfig } from "../../config";
import releaseNotesAdmin from "../ReleaseNotes/releaseNotesAdmin";

const VersionOverlay = ({ showReleaseNotes, releaseVersion, releaseAdmin }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const releases = pathname?.includes('/admin') ? releaseNotesAdmin(t) : releaseNotesArray(t);

  const currentRelease = pathname?.includes('/admin') ? releaseAdmin : releaseVersion;

  return (
    <div
      className={`version-overlay version-overlay-${identity} ${pathname !== '/checkouts' && (pathname.includes('/checkout') || pathname.includes('/charge') || pathname.includes('/sale-token-checkout') || pathname.includes('/sale-token-charge')) ? 'checkout' : null}`}
      onClick={() => showReleaseNotes(releases.find(release => release.version === currentRelease))}
    >
      v. {currentRelease}
    </div>
  );
};

const mapStateToProps = state => ({
  releaseVersion: state.user.releaseVersion,
  releaseAdmin: state.user.releaseAdmin,
});

const mapDispatchToProps = dispatch => ({
  showReleaseNotes: showReleaseNotes(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionOverlay);
