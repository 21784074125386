import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { Checkbox, Form } from "antd";
import Card from '../../components/Card';
import Switcher from '../../components/Switcher';
import SystemFeeSetting from '../../components/SystemFeeSetting';
import SystemFeeReplenishmentSetting from '../../components/SystemFeeReplenishmentSetting';
import WithdrawalSetting from '../../components/WithdrawalSetting';
import { ButtonPrimary, ButtonSecondary } from '../../components/Button';
import {
  getAdminSettings,
  updateAdminSettings,
  getRolesList,
  createNewRole ,
  deleteRole,
  getRolePermissions,
  changeRolePermissions
} from '../../../redux/actions/adminPanel/adminSettings';
import {scrollContainerIntoView} from "../../helpers/scrollIntoView";
import './style.scss';
import SystemRefund from '../../components/SystemRefund/SystemRefund';
import SystemFeeSwap from '../../components/SystemFeeSwap/SystemFeeSwap';
import SettingsToggler from "../../components/RoleSettings/SettingsToggler";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import { ReactComponent as EditIcon } from "../../../img/adminPanel/edit.svg";
import { ReactComponent as RemoveIcon } from "../../../img/adminPanel/remove.svg";
import { ReactComponent as EditIconAdmin } from "../../../img/adminPanel/editAdmin.svg";
import { ReactComponent as RemoveIconAdmin } from "../../../img/adminPanel/removeAdmin.svg";
import { ReactComponent as LinkIcon } from "../../../img/default-svg/linkAdmin.svg";
import AddRoleModal from "../../components/RoleSettings/AddRoleModal";
import AddUserModal from "../../components/RoleSettings/AddUserModal";
import RemoveRoleModal from "../../components/RoleSettings/RemoveRoleModal";
import { getAdminUsers, updateAdminUser } from "../../../redux/actions/adminPanel/adminUsers";
import { adminApi } from "../../../service/admin-api";
import SystemFeeClientsReplenishment from "../../components/SystemFeeClientsReplenishment";
import { Link } from "react-router-dom";

const Settings = ({
  adminSettingsData,
  adminSettingsFetching,
  getAdminSettings,
  updateAdminSettings,
  getRolesList,
  rolesList ,
  createNewRole,
  deleteRole,
  getRolePermissions,
  permissionsList,
  permissions,
  roleName,
  changeRolePermissions,
  getAdminUsers,
  usersList,
  usersCurrentPage,
  usersPages,
  updateAdminUser,
}) => {
  const [form] = Form.useForm();
  const adminSettingsRef = useRef();
  const [roleMode, setRoleMode] = useState(false);
  const [isOpenAddRole, setIsOpenAddRole] = useState(false);
  const [isOpenAddUser, setIsOpenAddUser] = useState(false);
  const [removeId, setRemoveId] = useState('');
  const [removeName, setRemoveName] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [permissionId, setPermissionId] = useState('');
  const [isOpenRemoveRole, setIsOpenRemoveRole] = useState(false);
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const { t } = useTranslation();

  const handleCheckboxChange = (name, field) => {
    setCurrentPermissions(prevPermissions =>
      prevPermissions.map(permission =>
        permission.name === name
          ? { ...permission, [field]: !permission[field] }
          : permission
      )
    );
  };

  const handleSubmit = () => {
    const newPermissions = currentPermissions.reduce((acc, { name, edit, view }) => {
      acc[name] = { edit, view };
      return acc;
    }, {});

    changeRolePermissions(permissionId, newPermissions);
    setEditMode(false);

    setTimeout(() => {
      getRolesList();
      getRolePermissions('edit', permissionId);
    }, 1000);

    setTimeout(() => {
      setPermissionId('');
    }, 1300);
  }

  const closeRemoveModal = () => {
    setRemoveId('');
    setRemoveName('');
    setIsOpenRemoveRole(false);
  };

  const editPermissions = (id) => {
    getRolePermissions('edit', id);
    setPermissionId(id);
    setEditMode(true);
  };

  const openRemoveModal = (id, name) => {
    setRemoveId(id);
    setRemoveName(name);
    setIsOpenRemoveRole(true);
  };

  const goBack = () => {
    if (!roleMode) {
      form.resetFields()
    } else {
      setEditMode(false);
    }
  }

  useEffect(() => {
    if (permissionsList) {
      setCurrentPermissions(permissionsList);
    }
  }, [permissionsList]);

  useEffect(() => {
    getAdminSettings();
    scrollContainerIntoView(adminSettingsRef);
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [adminSettingsData]);

  useEffect(() => {
    if (roleMode) {
      getRolesList();
    }
  }, [roleMode]);

  const updateUser = (userId, roleId) => {
    updateAdminUser(userId, { accessRole: roleId });
  }

  const changeRoleMode = (value) => {
    if (!value) {
      setRoleMode(false);
      setEditMode(false);
    } else {
      setRoleMode(true);
    }
  }

  const handleClick = (role) => {
    localStorage.setItem('role', role);
    setTimeout(() => window.location.href = "/admin/users", 50);
  };

  return (
    <div className="admin-settings" ref={adminSettingsRef}>
      <Card>
        <SettingsToggler
          firstMode={t('adminRole.systemFee')}
          secondMode={t('adminRole.roleManagement')}
          roleMode={roleMode}
          setRoleMode={changeRoleMode}
        />
        {!roleMode && !editMode && (
          <Form
            form={form}
            name="admin-settings-form"
            initialValues={{
              paySystemFee: adminSettingsData.paySystemFee,
              systemFeePercent: adminSettingsData.systemFeePercent,
              systemFeePercentReplenish: adminSettingsData.systemFeePercentReplenish,
              systemFeePercentClient: adminSettingsData.systemFeePercentClient,
              systemFeePercentSwap: adminSettingsData.systemFeePercentSwap,
              refundLimitUsd: adminSettingsData.refundLimitUsd,
              minimumWithdrawalUsd: adminSettingsData.minimumWithdrawalUsd,
            }}
            onFinish={updateAdminSettings}
          >
            <Switcher
              name="paySystemFee"
              title="System fee switcher"
              description="Turn on the switch if you want to charge a system fee"
            />
            <SystemFeeSetting name="systemFeePercent" />
            <SystemFeeClientsReplenishment name="systemFeePercentClient" />
            <SystemFeeReplenishmentSetting name="systemFeePercentReplenish" />
            <SystemFeeSwap name='systemFeePercentSwap' />
            <SystemRefund name='refundLimitUsd' />
            <WithdrawalSetting name="minimumWithdrawalUsd" />
          </Form>
        )}
        {roleMode && !editMode && (
          <div className="admin-settings__role">
            <div className="admin-settings__role--header">
              <div className="admin-settings__role--title">{t('adminRole.title')}</div>
              <div className="admin-settings__role--buttons">
                <Button
                  className="admin-settings__role--add"
                  type="primary"
                  onClick={() => setIsOpenAddUser(true)}
                  disabled={permissions && permissions?.length > 0 && !permissions[2]?.edit}
                >
                  {t('adminRole.addUser')}
                </Button>
                <Button
                  className="admin-settings__role--add"
                  type="primary"
                  onClick={() => setIsOpenAddRole(true)}
                  disabled={permissions && permissions?.length > 0 && !permissions[2]?.edit}
                >
                  {t('adminRole.addRole')}
                </Button>
              </div>
            </div>
            {rolesList && (
              <div>
                {rolesList?.map((item, index) => (
                  <div>
                    <div className="admin-settings__role--card">
                      <div
                        className="admin-settings__role--card-name">{item?.name.charAt(0).toUpperCase() + item?.name.slice(1)}</div>
                      {permissions && permissions?.length > 0 && permissions[2]?.edit && (
                        <div>
                          <LinkIcon onClick={() => handleClick(item?.name)} />
                          {item?.name === "admin" ? (
                            <EditIconAdmin />
                          ) : (
                            <EditIcon onClick={() => editPermissions(item?._id)} />
                          )}
                          {item?.name === "admin" ? (
                            <RemoveIconAdmin />
                          ) : (
                            <RemoveIcon
                              onClick={() => openRemoveModal(item?._id, item?.name.charAt(0).toUpperCase() + item?.name.slice(1))} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {editMode && (
          <div className="admin-settings__role">
            <div className="admin-settings__role--header">
              <div className="admin-settings__role--title">{t("adminRole.permissions")}</div>
            </div>
            <div className="admin-settings__role--permissions">
              <div className="admin-settings__role--permissions-header">
                <div className="admin-settings__role--permissions-actions">{t("adminRole.actions")}</div>
                <div className="admin-settings__role--permissions-options">
                  <div>{t("adminRole.view")}</div>
                  <div>{t("adminRole.edit")}</div>
                </div>
              </div>
              <div className="admin-settings__role--permissions-role">
                {roleName}
              </div>
              <div className="admin-settings__role--permissions-checkbox">
                {currentPermissions?.map((item) => (
                  <div className="admin-settings__role--permissions-line">
                    <div className="admin-settings__role--permissions-name">{item?.name.charAt(0).toUpperCase() + item?.name.slice(1)}</div>
                    <div>
                      <Checkbox
                        onChange={() => handleCheckboxChange(item?.name, 'view')}
                        checked={item?.view}
                      />
                      <Checkbox
                        onChange={() => handleCheckboxChange(item?.name, 'edit')}
                        checked={item?.edit}
                        className="admin-settings__role--permissions-check"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Card>
      {(!roleMode || (roleMode && editMode)) && (
        <div className="admin-settings-buttons">
          <ButtonSecondary onClick={goBack}>
            Cancel
          </ButtonSecondary>
          <ButtonPrimary
            type="submit"
            onClick={!roleMode ? form.submit : handleSubmit}
            disabled={adminSettingsFetching || (permissions && permissions?.length > 0 && !permissions[2]?.edit)}
            loading={adminSettingsFetching}
          >
            Save
          </ButtonPrimary>
        </div>
      )}
      <AddRoleModal
        getRolesList={getRolesList}
        createNewRole={createNewRole}
        visible={isOpenAddRole}
        close={() => setIsOpenAddRole(false)}
      />
      <AddUserModal
        visible={isOpenAddUser}
        close={() => setIsOpenAddUser(false)}
        getAdminUsers={getAdminUsers}
        usersList={usersList}
        usersCurrentPage={usersCurrentPage}
        usersPages={usersPages}
        rolesList={rolesList}
        updateAdminUser={updateUser}
      />
      <RemoveRoleModal
        getRolesList={getRolesList}
        visible={isOpenRemoveRole}
        deleteRole={deleteRole}
        close={closeRemoveModal}
        removeId={removeId}
        removeName={removeName}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  usersList: state.adminUsers.entities,
  adminSettingsData: state.adminSettings.data,
  adminSettingsFetching: state.adminSettings.fetching,
  rolesList: state.adminSettings.rolesList,
  permissionsList: state.adminSettings.permissionsList,
  permissions: state.adminSettings.permissions,
  roleName: state.adminSettings.roleName,
  usersCurrentPage: state.adminUsers.page,
  usersPages: state.adminUsers.pages,
});

const mapDispatchToProps = {
  getAdminSettings,
  updateAdminSettings,
  getRolesList,
  createNewRole,
  deleteRole,
  getRolePermissions,
  changeRolePermissions,
  getAdminUsers,
  updateAdminUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
