import {
  CURRENCIES_GET,
  CURRENCIES_SET,
  CURRENCIES_ERROR,
  CREATE_CURRENCY_REQUEST,
  CREATE_CURRENCY_SUCCESS,
  CREATE_CURRENCY_FAIL,
  APPROVE_CURRENCY_REQUEST,
  APPROVE_CURRENCY_SUCCESS,
  APPROVE_CURRENCY_FAIL,
  APPROVE_TOKEN_OPEN_MODAL,
  APPROVE_TOKEN_CLOSE_MODAL
} from '../actions/currencies';

export default function reducer(
  state = {
    data: [],
    fetching: false,
    page: 1,
    pages: null,
    token: { requestId: null, tokenMetadata: null, fetching: false },
    isApproved: { created: false, fetching: false },
    isOpenModal: false
  },
  action
) {
  switch (action.type) {
    case CURRENCIES_GET:
      return {
        ...state,
        fetching: action.fetching,
      };
    case APPROVE_TOKEN_OPEN_MODAL:
      return {
        ...state,
        isOpenModal: true
      }
    case APPROVE_TOKEN_CLOSE_MODAL:
      return {
        ...state,
        isOpenModal: false
      }
    case CURRENCIES_SET:
      return {
        ...state,
        data: action.payload.currencies,
        page: action.payload.page,
        pages: action.payload.pages,
        fetching: action.fetching,
      };
    case CURRENCIES_ERROR:
      return {
        ...state,
        fetching: false,
      };
    case CREATE_CURRENCY_REQUEST:
      return {
        ...state,
        token: {
          ...state.token,
          fetching: true
        }
      }
    case CREATE_CURRENCY_SUCCESS:
      return {
        ...state,
        token: {
          ...state.token,
          requestId: action.payload.requestId,
          tokenMetadata: action.payload.tokenMetadata,
          fetching: false
        }
      }
    case CREATE_CURRENCY_FAIL:
      return {
        ...state,
        token: {
          requestId: null,
          tokenMetadata: null,
          fetching: false
        }
      }
    case APPROVE_CURRENCY_REQUEST:
      return {
        ...state,
        isApproved: {
          ...state.isApproved,
          fetching: true
        }
      }
    case APPROVE_CURRENCY_SUCCESS:
      return {
        ...state,
        isApproved: {
          ...state.isApproved,
          created: true,
          fetching: false
        }
      }
    case APPROVE_CURRENCY_FAIL:
      return {
        ...state,
        isApproved: {
          created: false,
          fetching: false
        }
      }
    default:
      return state;
  }
}
