import React, { useState, useRef } from 'react';
import { connect, useDispatch } from "react-redux";
import { change, Field, Form as ReduxForm, reduxForm } from "redux-form";
import { Input, Dropdown, Row, Col, Checkbox, Radio } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../utils/resizeHook';
import resetCpayIcon from '../../img/cpay-svg/reset.svg';
import resetNfgIcon from '../../img/nfgpay-svg/reset-nfg.svg';
import resetFinvaroIcon from '../../img/finvaro/reset-finvaro.svg';
import resetClarniumIcon from '../../img/clarnium/reset-clarnium.svg';
import './WalletsFilters.scss';
import { identity } from '../../utils/getIdentity';
import { useLocation } from "react-router-dom";

const resetIcon = {
  cpay: resetCpayIcon,
  nfg: resetNfgIcon,
  finvaro: resetFinvaroIcon,
  clarnium: resetClarniumIcon
}

const RenderSearch = ({ input, placeholder, onSearch, className, addonAfter, disabled }) => {
  return (
    <Input.Search
      disabled={disabled}
      {...input}
      placeholder={placeholder}
      onSearch={onSearch}
      className={className}
      addonAfter={addonAfter}
    />
  );
};

const RenderCheckbox = ({ input, options, disabled }) => {
  return <Checkbox.Group disabled={disabled} {...input} options={options} />;
};

const RenderRadio = ({ input, options, disabled }) => {
  return (
    <Radio.Group disabled={disabled} {...input}>
      {options.map((option, index) => (
        <Radio key={index} value={option.value}>
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

const WalletsFilters = ({ currencies, submitFilters, resetFilters, fetching, merchantName, hideZeroCheckbox, setCheckbox, typeWallets }) => {
  const [isCurrenciesVisible, setIsCurrenciesVisible] = useState(false);
  const [isSortingVisible, setIsSortingVisible] = useState(false);
  const { currentWidth } = useWindowSize();
  const formRef = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();

  const dispatch = useDispatch();
  const reduxId = location.pathname === '/wallets' ? 'walletsFilters' : 'merchantWalletsFilters'

  const handleSelectAll = () => {
    const allValues = currencies.map(currency => currency._id);
    dispatch(change(reduxId, "currencyIds", allValues));
  };

  const handleClearAll = () => {
    dispatch(change(reduxId, "currencyIds", []));
  };

  const handleReset = () => {
    formRef.current.props._reduxForm.reset();
    resetFilters();
  };

  return (
    <ReduxForm ref={formRef} onSubmit={e => e.preventDefault()}>
      <div className="wallet-filters">
        <Row gutter={[20]}>
          <Col span={currentWidth >= 1024 ? 11 : 24} className="component__title wallet-filters__title">
            {t('merchants.merchant')} {merchantName} {t('wallets.wallets').toLowerCase()}
          </Col>
          <Col span={currentWidth >= 1024 ? 7 : 24} className="wallet-filters__search">
            <Field
              disabled={fetching}
              name="search"
              component={RenderSearch}
              placeholder={t("wallets.placeholder")}
              onSearch={submitFilters}
              onFocus={e => e.preventDefault()}
              onBlur={e => e.preventDefault()}
              className="wallet-filters__search-field"
              addonAfter={
                <span onClick={handleReset} className="wallet-filters__reset-button">
                  <img src={resetIcon[identity]} alt="Reset" />
                </span>
              }
            />
          </Col>
          <Col span={currentWidth >= 1024 ? 3 : 12} className="wallet-filters__currencies">
            <Dropdown
              onVisibleChange={visibilityState => setIsCurrenciesVisible(visibilityState)}
              visible={isCurrenciesVisible}
              overlay={
                <div className="wallet-filters__currencies-dropdown">
                  <div className="wallet-filters__currencies-select">
                    <div
                      className="wallet-filters__currencies-select-all"
                      onClick={handleSelectAll}
                    >
                      Select all
                    </div>
                    <div
                      className="wallet-filters__currencies-select-clear"
                      onClick={handleClearAll}
                    >
                      Clear all
                    </div>
                  </div>
                  <Field
                    disabled={fetching}
                    name="currencyIds"
                    options={currencies
                      .filter(currency => currency.currencyType === "currency")
                      .map(currency => ({ label: currency.title, value: currency._id }))}
                    component={RenderCheckbox}
                    onFocus={e => e.preventDefault()}
                    onBlur={e => e.preventDefault()}
                  />
                  <div
                    className={`wallet-filters__currencies-dropdown_apply wallet-filters__currencies-dropdown_apply-${identity}`}
                    onClick={!fetching ? submitFilters : null}>
                    {t("apply")}
                  </div>
                </div>
              }
            >
              <div className={`wallet-filters__currencies-button wallet-filters__currencies-button-${identity}`}>
                {t("currency")} <DownOutlined />
              </div>
            </Dropdown>
          </Col>
          <Col span={currentWidth >= 1024 ? 3 : 12} className="wallet-filters__sorting">
            <Dropdown
              onVisibleChange={visibilityState => setIsSortingVisible(visibilityState)}
              visible={isSortingVisible}
              overlay={
                <div className="wallet-filters__currencies-dropdown">
                  <Field
                    disabled={fetching}
                    name="order"
                    options={[
                      { label: t('first'), value: 'DESC' },
                      { label: t('last'), value: 'ASC' },
                    ]}
                    component={RenderRadio}
                    onFocus={e => e.preventDefault()}
                    onBlur={e => e.preventDefault()}
                  />
                  <div className="wallet-filters__currencies-zero">
                    <Checkbox
                      checked={hideZeroCheckbox}
                      onChange={() => {
                        localStorage.setItem(typeWallets === 'user' ? 'cpayHideZeroBalancesClient' : 'cpayHideZeroBalancesMerchant', !hideZeroCheckbox);
                        setCheckbox(!hideZeroCheckbox)}
                      }
                    >
                      {t('homePage.transactions.zeroBal')}
                    </Checkbox>
                  </div>
                </div>
              }
            >
              <div className="wallet-filters__currencies-button">
                {t('sorting')} <DownOutlined />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </ReduxForm>
  );
};

const FiltersFormContainer = reduxForm({ destroyOnUnmount: true })(WalletsFilters);

export default connect((state, { formName }) => ({
  form: formName,
}))(FiltersFormContainer);
