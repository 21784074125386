import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { showReleaseNotes, hideReleaseNotes } from '../../../redux/actions/releaseNotes';
import { Modal } from 'antd';
import releaseNotesArray from '../releaseNotesArray';
import './style.scss';
import { AppConfig } from '../../../config';
import releaseNotesAdmin from "../releaseNotesAdmin";

const ReleaseNotesModal = ({ releaseNote, showReleaseNotes, hideReleaseNotes, sessionData, isShowWelcomeModal, releaseVersion, releaseAdmin }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const releases = pathname?.includes('/admin') ? releaseNotesAdmin(t) : releaseNotesArray(t);

  const location = useLocation();
  const currentRelease = pathname?.includes('/admin') ? releaseAdmin : releaseVersion;
  useEffect(() => {
    const userLastVersion = (pathname?.includes('/admin') ? localStorage.getItem('cpayAdminLastVersion') : localStorage.getItem('cpayLastVersion')) ?? 1;

    if (!isShowWelcomeModal && sessionData && userLastVersion !== currentRelease) {
      showReleaseNotes(releases.find(release => release.version === currentRelease));
    }
  }, [sessionData, pathname]);

  const handleCloseModal = () => {
    localStorage.setItem(pathname?.includes('/admin') ? 'cpayAdminLastVersion' :'cpayLastVersion', currentRelease);
    hideReleaseNotes();
  };

  return (
    AppConfig.identity === 'cpay' && (
        location.pathname.indexOf('checkout/') >= 0 &&
        location.pathname.indexOf('sale-token-checkout/') >= 0 ? null : (
        <Modal
          title={
            <>
              <div className="release-notes-modal__title">CPAY {releaseNote && releaseNote.version}</div>
              {releaseNote && (
                <span className="release-notes-modal__subtitle">
                  {t(releaseNote.month)} {t(releaseNote.time.toLowerCase())}{' '}
                </span>
              )}
            </>
          }
          className="release-notes-modal"
          visible={releaseNote}
          onOk={handleCloseModal}
          onCancel={handleCloseModal}
          footer={null}
        >
          {releaseNote &&
            releaseNote.sections.map((section, index) => (
              <ul key={`${section.changes[0]}`} className="release-notes-modal__list">
                {section.title}
                {section.changes.map(change => (
                  <li key={change} className="release-notes-modal__list-item">
                    {change}
                  </li>
                ))}
              </ul>
            ))}
          {releaseNote && releaseNote.link ? (
            <a className="release-notes-modal__read-more" href={releaseNote && releaseNote.link} target="_blank" rel="noopener noreferrer">
              {t('releases.more')}
            </a>
          ) : (
            <></>
          )}
        </Modal>
      )
        )
  )


};

const mapStateToProps = state => ({
  releaseNote: state.releaseNotes,
  releaseVersion: state.user.releaseVersion,
  releaseAdmin: state.user.releaseAdmin,
});

const mapDispatchToProps = dispatch => ({
  showReleaseNotes: showReleaseNotes(dispatch),
  hideReleaseNotes: hideReleaseNotes(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotesModal);
