import React from 'react';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../../utils/resizeHook';
import { ReactComponent as FloatingIcon } from '../../../img/swap-svg/swap-floating.svg';
import { ReactComponent as FixedIcon } from '../../../img/swap-svg/swap-fixed.svg';
import getSwapPartnerIcon from '../../../utils/swapPartnerIcons';
import SwapOfferTrustscore from '../SwapOfferTrustscore';
import './style.scss';
import getSwapPartnerTrustscore from '../../../utils/swapPartnerTrustscore';
import { identity } from '../../../utils/getIdentity';
import SwapKYCLevel from '../SwapKYCLevel';
import { ReactComponent as InfoCircle } from '../../../img/default-svg/info-circle-kyc.svg';
import { Tooltip } from 'antd';
import SwapKYCTooltip from '../SwapKYCTooltip';

const OfferItem = ({ offer, index, handleExchange, showUpdateRatesBanner, showBalanceWarning, showBestLabel }) => {
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();

  const offerTypes = {
    true: t('swap.offerItem.fixed'),
    false: t('swap.offerItem.floating'),
  };

  return currentWidth > 590 ? (
    <div className="offer-item">
      <div className="offer-item__row">
        <div className={`offer-item__column offer-item__column-${identity} offer-item__rate`}>
          <span className="offer-item__column-name">
            {t('swap.offerItem.rate')}{' '}
            {showBestLabel && identity === 'cpay' && (
              <span className={`offer-item__best offer-item__best-${identity}`}>{t('swap.offerItem.best')}</span>
            )}
          </span>
          <span className="offer-item__column-value">
            {offer.min && offer.fromAmount < offer.min
              ? `min ${offer.min} ${offer.fromCurrency.toUpperCase()}`
              : offer.max && offer.fromAmount > offer.max
              ? `max ${offer.max} ${offer.fromCurrency.toUpperCase()}`
              : offer.toAmount}
          </span>
        </div>
        {identity === 'cpay' && (
          <div className={`offer-item__column offer-item__column-${identity} offer-item__type`}>
            <span className="offer-item__column-name">{t('swap.offerItem.type')}</span>
            <span className="offer-item__column-value">
              {offer.fixed && offer.id ? <FixedIcon /> : <FloatingIcon />} {offerTypes[offer.id ? offer.fixed : false]}
            </span>
          </div>
        )}
      </div>

      <div className="offer-item__row">
        <div className={`offer-item__column offer-item__column-${identity} offer-item__eta`}>
          <span className="offer-item__column-name">ETA</span>
          <span className="offer-item__column-value">{offer.duration} min</span>
        </div>
        {identity === 'cpay' && (
          <div className={`offer-item__column offer-item__column-${identity} offer-item__support-rate`}>
            <span className="offer-item__column-name">{t('swap.offerItem.support')}</span>
            <span className="offer-item__column-value">
              <div
                className={`offer-item__support-rate-bar offer-item__support-rate-bar-${identity}`}
                data-score={offer.supportRate}
              ></div>
            </span>
          </div>
        )}
      </div>
      {identity === 'cpay' && (
        <div className="offer-item__row">
          <div className={`offer-item__column offer-item__column-${identity} offer-item__partner`}>
            <span className="offer-item__column-name">{t('swap.offerItem.partner')}</span>
            <span className="offer-item__column-value">
              <img src={getSwapPartnerIcon(offer.partner)} alt={offer.partner} /> {offer.partner}
            </span>
          </div>

          <div className={`offer-item__column offer-item__column-${identity} offer-item__trust-score`}>
            <span className="offer-item__column-name">{t('swap.offerItem.score')}</span>
            <span className="offer-item__column-value">
              <SwapOfferTrustscore
                key={`${offer.id}-${offer.max}-${offer.min}-${offer.partner}`}
                partnerName={offer.partner}
              />
            </span>
          </div>
        </div>
      )}

      <div className="offer-item__exchange">
        <div className="offer-item__exchange_kyc-block">
          <span className="offer-item__column-name">{t('swap.offerItem.kycRisk')}</span>
          <Tooltip
            autoAdjustOverflow={true}
            placement="bottomRight"
            title={<SwapKYCTooltip />}
            overlayClassName="offer-item__exchange_kyc-tooltip"
          >
            <InfoCircle />
          </Tooltip>
        </div>

        <SwapKYCLevel level={getSwapPartnerTrustscore(offer.partner)?.kycRisk} />

        {identity === 'cpay' && (
          <button
            type="button"
            className={`offer-item__exchange-button offer-item__exchange-button-${identity}`}
            disabled={
              showBalanceWarning ||
              showUpdateRatesBanner ||
              (offer.min && offer.fromAmount < offer.min) ||
              (offer.max && offer.fromAmount > offer.max)
            }
            onClick={() => handleExchange(offer.partner, offer.fixed, offer.toAmount, offer.id)}
          >
            {t('swap.offerItem.exchange')}
          </button>
        )}
      </div>
      {identity !== 'cpay' && (
        <div>
          <button
            type="button"
            className={`offer-item__exchange-button offer-item__exchange-button-${identity}`}
            disabled={
              showBalanceWarning ||
              showUpdateRatesBanner ||
              (offer.min && offer.fromAmount < offer.min) ||
              (offer.max && offer.fromAmount > offer.max)
            }
            onClick={() => handleExchange(offer.partner, offer.fixed, offer.toAmount, offer.id)}
          >
            {t('swap.offerItem.exchange')}
          </button>
        </div>
      )}
    </div>
  ) : (
    <div className="offer-item">
      <div className="offer-item-mobile__info">
        <div className="offer-item-mobile__row">
          <div>
            {identity === 'cpay' ? (
              <div className="offer-item__column offer-item__partner">
                <span className="offer-item__column-value">
                  <img src={getSwapPartnerIcon(offer.partner)} alt={offer.partner} /> {offer.partner}
                </span>
              </div>
            ) : (
              <span className="offer-item__column-name offer-item__column-name-forNfg">
                {t('swap.offerItem.rate')}{' '}
              </span>
            )}

            <div className="offer-item__column offer-item__rate">
              <span className="offer-item__column-value">
                {offer.min && offer.fromAmount < offer.min
                  ? `min ${offer.min} ${offer.fromCurrency.toUpperCase()}`
                  : offer.max && offer.fromAmount > offer.max
                  ? `max ${offer.max} ${offer.fromCurrency.toUpperCase()}`
                  : offer.toAmount}
              </span>
            </div>
          </div>

          <div className="offer-item__exchange">
            <div className="offer-item__exchange_kyc-block">
              <span className="offer-item__column-name">{t('swap.offerItem.kycRisk')}</span>
              <Tooltip
                autoAdjustOverflow={true}
                placement="bottomRight"
                title={<SwapKYCTooltip />}
                overlayClassName="offer-item__exchange_kyc-tooltip"
              >
                <InfoCircle />
              </Tooltip>
            </div>

            <SwapKYCLevel level={getSwapPartnerTrustscore(offer.partner)?.kycRisk} />
          </div>
        </div>
        {identity === 'cpay' && (
          <div className="offer-item-mobile__row">
            <div className="offer-item__column offer-item__trust-score">
              <span className="offer-item__column-name">{t('swap.offerItem.score')}</span>
              <span className="offer-item__column-value">
                <SwapOfferTrustscore
                  key={`${offer.id}-${offer.max}-${offer.min}-${offer.partner}`}
                  partnerName={offer.partner}
                />
              </span>
            </div>
            <div className="offer-item__column offer-item__support-rate">
              <span className="offer-item__column-name">{t('swap.offerItem.support')}</span>
              <span className="offer-item__column-value">
                <div
                  className={`offer-item__support-rate-bar offer-item__support-rate-bar-${identity}`}
                  data-score={offer.supportRate}
                ></div>
              </span>
            </div>
          </div>
        )}

        <div className="offer-item-mobile__row">
          {identity === 'cpay' && (
            <div className="offer-item__column offer-item__type">
              <span className="offer-item__column-name">{t('swap.offerItem.type')}</span>
              <span className="offer-item__column-value">
                {offer.fixed && offer.id ? <FixedIcon /> : <FloatingIcon />}{' '}
                {offerTypes[offer.id ? offer.fixed : false]}
              </span>
            </div>
          )}

          <div className="offer-item__column offer-item__eta">
            <span className="offer-item__column-name">ETA</span>
            <span className="offer-item__column-value">{offer.duration} min</span>
          </div>
        </div>
        <button
          type="button"
          className={`offer-item__exchange-button offer-item__exchange-button-${identity}`}
          disabled={
            showBalanceWarning ||
            showUpdateRatesBanner ||
            (offer.min && offer.fromAmount < offer.min) ||
            (offer.max && offer.fromAmount > offer.max)
          }
          onClick={() => handleExchange(offer.partner, offer.fixed, offer.toAmount, offer.id)}
        >
          {t('swap.offerItem.exchange')}
        </button>
      </div>
    </div>
  );
};

export default OfferItem;
