const releaseNotesAdmin = t => {
  return [
    {
      version: '2.1',
      date: 'February 12, 2025',
      month: 'February',
      time: '11, 2025',
      link: 'https://admin-docs.cpay.world/changes/release-notes/release-2.1',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releasesAdmin.versionTwoOne.sections.first.changes.1'),
            t('releasesAdmin.versionTwoOne.sections.first.changes.2'),
            t('releasesAdmin.versionTwoOne.sections.first.changes.3'),
            t('releasesAdmin.versionTwoOne.sections.first.changes.4'),
          ],
        },
      ],
    },
  ];
};

export default releaseNotesAdmin;
