import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsSuccessShow, notificationsErrorShow } from './notifications';
import { getMerchantApiKeysSuccess } from './merchantData';

// CONSTANTS
//--GET CHECKOUTS LIST
export const GET_CHECKOUTS_LIST_REQUEST = 'GET_CHECKOUTS_LIST_REQUEST';
export const GET_CHECKOUTS_LIST_SUCCESS = 'GET_CHECKOUTS_LIST_SUCCESS';
export const GET_CHECKOUTS_LIST_FAIL = 'GET_CHECKOUTS_LIST_FAIL';
//--CREATE CHECKOUT DONATION
export const CREATE_CHECKOUT_DONATION_REQUEST = 'CREATE_CHECKOUT_DONATION_REQUEST';
export const CREATE_CHECKOUT_DONATION_SUCCESS = 'CREATE_CHECKOUT_DONATION_SUCCESS';
export const CREATE_CHECKOUT_DONATION_FAIL = 'CREATE_CHECKOUT_DONATION_FAIL';
//--CREATE CHECKOUT SALE
export const CREATE_CHECKOUT_SALE_REQUEST = 'CREATE_CHECKOUT_SALE_REQUEST';
export const CREATE_CHECKOUT_SALE_SUCCESS = 'CREATE_CHECKOUT_SALE_SUCCESS';
export const CREATE_CHECKOUT_SALE_FAIL = 'CREATE_CHECKOUT_SALE_FAIL';
//--CREATE CHECKOUT SELL CURRENCY
export const CREATE_CHECKOUT_SELL_CURRENCY_REQUEST = 'CREATE_CHECKOUT_SELL_CURRENCY_REQUEST';
export const CREATE_CHECKOUT_SELL_CURRENCY_SUCCESS = 'CREATE_CHECKOUT_SELL_CURRENCY_SUCCESS';
export const CREATE_CHECKOUT_SELL_CURRENCY_FAIL = 'CREATE_CHECKOUT_SELL_CURRENCY_FAIL';
//--CREATE CHECKOUT CART
export const CREATE_CHECKOUT_CART_REQUEST = 'CREATE_CHECKOUT_CART_REQUEST';
export const CREATE_CHECKOUT_CART_SUCCESS = 'CREATE_CHECKOUT_CART_SUCCESS';
export const CREATE_CHECKOUT_CART_FAIL = 'CREATE_CHECKOUT_CART_FAIL';
//--DELETE CHECKOUT
export const DELETE_CHECKOUT_REQUEST = 'DELETE_CHECKOUT_REQUEST';
export const DELETE_CHECKOUT_SUCCESS = 'DELETE_CHECKOUT_SUCCESS';
export const DELETE_CHECKOUT_FAIL = 'DELETE_CHECKOUT_FAIL';
//--PATCH CHECKOUT
export const PATCH_CHECKOUT_REQUEST = 'PATCH_CHECKOUT_REQUEST';
export const PATCH_CHECKOUT_SUCCESS = 'PATCH_CHECKOUT_SUCCESS';
export const PATCH_CHECKOUT_FAIL = 'PATCH_CHECKOUT_FAIL';
//--SET DETAILED CHECKOUT
export const SET_DETAILED_CHECKOUT = 'SET_DETAILED_CHECKOUT';
export const SET_EDIT_CHECKOUT = 'SET_EDIT_CHECKOUT';
// GET SUPPORTED CURRENCIES LIST
export const GET_SUPPORTED_CURRENCIES_LIST_REQUEST = 'GET_SUPPORTED_CURRENCIES_LIST_REQUEST';
export const GET_SUPPORTED_CURRENCIES_LIST_SUCCESS = 'GET_SUPPORTED_CURRENCIES_LIST_SUCCESS';
export const GET_SUPPORTED_CURRENCIES_LIST_FAIL = 'GET_SUPPORTED_CURRENCIES_LIST_FAIL';
// GET SUPPORTED WALLETS LIST
export const GET_SUPPORTED_WALLETS_LIST_REQUEST = 'GET_SUPPORTED_WALLETS_LIST_REQUEST';
export const GET_SUPPORTED_WALLETS_LIST_SUCCESS = 'GET_SUPPORTED_WALLETS_LIST_SUCCESS';
export const GET_SUPPORTED_WALLETS_LIST_FAIL = 'GET_SUPPORTED_WALLETS_LIST_FAIL';
// CREATE NEW PLAN
export const CREATE_NEW_PLAN_REQUEST = 'CREATE_NEW_PLAN_REQUEST';
export const CREATE_NEW_PLAN_SUCCESS = 'CREATE_NEW_PLAN_SUCCESS';
export const CREATE_NEW_PLAN_FAIL = 'CREATE_NEW_PLAN_FAIL';
//GET PLANS LIST
export const GET_PLANS_LIST_REQUEST = 'GET_PLANS_LIST_REQUEST';
export const GET_PLANS_LIST_SUCCESS = 'GET_PLANS_LIST_SUCCESS';
export const GET_PLANS_LIST_FAIL = 'GET_PLANS_LIST_FAIL';
// PATCH PLAN
export const PATCH_PLAN_REQUEST = 'PATCH_PLAN_REQUEST';
export const PATCH_PLAN_SUCCESS = 'PATCH_PLAN_SUCCESS';
export const PATCH_PLAN_FAIL = 'PATCH_PLAN_FAIL';
// GET SUBSCRIPTIONS
export const GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_FAIL = 'GET_SUBSCRIPTIONS_FAIL';
// GET ACTIVITIES
export const GET_ACTIVITIES_REQUEST = 'GET_ACTIVITIES_REQUEST';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_FAIL = 'GET_ACTIVITIES_FAIL';
// CANCEL SUBSCRIPTION
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';
// GET MERCHANT SECURITY
export const GET_MERCHANT_SECURITY_REQUEST = 'GET_MERCHANT_SECURITY_REQUEST';
export const GET_MERCHANT_SECURITY_SUCCESS = 'GET_MERCHANT_SECURITY_SUCCESS';
export const GET_MERCHANT_SECURITY_FAIL = 'GET_MERCHANT_SECURITY_FAIL';
// CHANGE MERCHANT SECURITY
export const CHANGE_MERCHANT_SECURITY_REQUEST = 'CHANGE_MERCHANT_SECURITY_REQUEST';
export const CHANGE_MERCHANT_SECURITY_SUCCESS = 'CHANGE_MERCHANT_SECURITY_SUCCESS';
export const CHANGE_MERCHANT_SECURITY_FAIL = 'CHANGE_MERCHANT_SECURITY_FAIL';
export const SET_MERCHANT_SECURITY_TWO_FACTOR_TOKEN = 'SET_MERCHANT_SECURITY_TWO_FACTOR_TOKEN';
export const SET_MERCHANT_SECURITY_EMAIL = 'SET_MERCHANT_SECURITY_EMAIL';
export const SET_MERCHANT_SECURITY_VERIFY_CODE = 'SET_MERCHANT_SECURITY_VERIFY_CODE';
// ACTIONS GENERATORS

//--GET CHECKOUTS LIST
const getCheckoutListRequest = () => ({
  type: GET_CHECKOUTS_LIST_REQUEST,
});

const getCheckoutListSuccess = payload => ({
  type: GET_CHECKOUTS_LIST_SUCCESS,
  payload,
});

const getCheckoutListFail = payload => ({
  type: GET_CHECKOUTS_LIST_FAIL,
  payload,
});

//--CREATE CHECKOUT DONATION
const createCheckoutDonationRequest = () => ({
  type: CREATE_CHECKOUT_DONATION_REQUEST,
});
const createCheckoutDonationSuccess = payload => ({
  type: CREATE_CHECKOUT_DONATION_SUCCESS,
  payload,
});
const createCheckoutDonationFail = payload => ({
  type: CREATE_CHECKOUT_DONATION_FAIL,
  payload,
});

//--CREATE CHECKOUT SALE
const createCheckoutSaleRequest = () => ({
  type: CREATE_CHECKOUT_SALE_REQUEST,
});
const createCheckoutSaleSuccess = payload => ({
  type: CREATE_CHECKOUT_SALE_SUCCESS,
  payload,
});
const createCheckoutSaleFail = payload => ({
  type: CREATE_CHECKOUT_SALE_FAIL,
  payload,
});

//--CREATE CHECKOUT SELL CURRENCY
const createCheckoutSellCurrencyRequest = () => ({
  type: CREATE_CHECKOUT_SELL_CURRENCY_REQUEST,
});
const createCheckoutSellCurrencySuccess = payload => ({
  type: CREATE_CHECKOUT_SELL_CURRENCY_SUCCESS,
  payload,
});
const createCheckoutSellCurrencyFail = payload => ({
  type: CREATE_CHECKOUT_SELL_CURRENCY_FAIL,
  payload,
});

//--CREATE CHECKOUT CART
const createCheckoutCartRequest = () => ({
  type: CREATE_CHECKOUT_CART_REQUEST,
});
const createCheckoutCartSuccess = payload => ({
  type: CREATE_CHECKOUT_CART_SUCCESS,
  payload,
});
const createCheckoutCartFail = payload => ({
  type: CREATE_CHECKOUT_CART_FAIL,
  payload,
});

//--DELETE CHECKOUT
const deleteCheckoutRequest = () => ({
  type: DELETE_CHECKOUT_REQUEST,
});
const deleteCheckoutSuccess = payload => ({
  type: DELETE_CHECKOUT_SUCCESS,
  payload,
});
const deleteCheckoutFail = payload => ({
  type: DELETE_CHECKOUT_FAIL,
  payload,
});

//--PATCH CHECKOUT
const patchCheckoutRequest = () => ({
  type: PATCH_CHECKOUT_REQUEST,
});
const patchCheckoutSuccess = payload => ({
  type: PATCH_CHECKOUT_SUCCESS,
  payload,
});
const patchCheckoutFail = payload => ({
  type: PATCH_CHECKOUT_FAIL,
  payload,
});

//--SET DETAILED CHECKOUT
const setDetailed = payload => ({
  type: SET_DETAILED_CHECKOUT,
  payload,
});

const setEdit = payload => ({
  type: SET_EDIT_CHECKOUT,
  payload,
});

// GET SUPPORTED CURRENCIES LIST

const getSupportedCurrenciesListRequest = () => ({
  type: GET_SUPPORTED_CURRENCIES_LIST_REQUEST,
});

const getSupportedCurrenciesListSuccess = payload => ({
  type: GET_SUPPORTED_CURRENCIES_LIST_SUCCESS,
  payload,
});

const getSupportedCurrenciesListFail = payload => ({
  type: GET_SUPPORTED_CURRENCIES_LIST_FAIL,
  payload,
});

// GET SUPPORTED WALLETS LIST

const getSupportedWalletsListRequest = () => ({
  type: GET_SUPPORTED_WALLETS_LIST_REQUEST,
});

const getSupportedWalletsListSuccess = payload => ({
  type: GET_SUPPORTED_WALLETS_LIST_SUCCESS,
  payload,
});

const getSupportedWalletsListFail = payload => ({
  type: GET_SUPPORTED_WALLETS_LIST_FAIL,
  payload,
});

// CREATE NEW PLAN

const createNewPlanRequest = () => ({
  type: CREATE_NEW_PLAN_REQUEST,
});

const createNewPlanSuccess = payload => ({
  type: CREATE_NEW_PLAN_SUCCESS,
  payload,
});

const createNewPlanFail = payload => ({
  type: CREATE_NEW_PLAN_FAIL,
  payload,
});

// GET PLANS LIST

const getPlansListRequest = () => ({
  type: GET_PLANS_LIST_REQUEST,
});

const getPlansListSuccess = payload => ({
  type: GET_PLANS_LIST_SUCCESS,
  payload,
});

const getPlansListFail = payload => ({
  type: GET_PLANS_LIST_FAIL,
  payload,
});

// PATCH PLAN

const patchPlanRequest = () => ({
  type: PATCH_PLAN_REQUEST,
});

const patchPlanSuccess = payload => ({
  type: PATCH_PLAN_SUCCESS,
  payload,
});

const patchPlanFail = payload => ({
  type: PATCH_PLAN_FAIL,
  payload,
});

// GET SUBSCRIPTIONS

const getSubscriptionsRequest = () => ({
  type: GET_SUBSCRIPTIONS_REQUEST,
});

const getSubscriptionsSuccess = payload => ({
  type: GET_SUBSCRIPTIONS_SUCCESS,
  payload,
});

const getSubscriptionsFail = payload => ({
  type: GET_SUBSCRIPTIONS_FAIL,
  payload,
});

// GET ACTIVITIES

const getActivitiesRequest = () => ({
  type: GET_ACTIVITIES_REQUEST,
});

const getActivitiesSuccess = payload => ({
  type: GET_ACTIVITIES_SUCCESS,
  payload,
});

const getActivitiesFail = payload => ({
  type: GET_ACTIVITIES_FAIL,
  payload,
});

// CANCEL SUBSCRIPTION

const cancelSubscriptionRequest = () => ({
  type: CANCEL_SUBSCRIPTION_REQUEST
});

const cancelSubscriptionSuccess = payload => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  payload
});

export const cancelSubscriptionFail = payload => ({
  type: CANCEL_SUBSCRIPTION_FAIL,
  payload
});

// GET MERCHANT SECURITY

const getMerchantSecurityRequest = () => ({
  type: GET_MERCHANT_SECURITY_REQUEST,
});

const getMerchantSecuritySuccess = payload => ({
  type: GET_MERCHANT_SECURITY_SUCCESS,
  payload,
});

const getMerchantSecurityFail = payload => ({
  type: GET_MERCHANT_SECURITY_FAIL,
  payload,
});

// CHANGE MERCHANT SECURITY

const changeMerchantSecurityRequest = () => ({
  type: CHANGE_MERCHANT_SECURITY_REQUEST,
});

export const changeMerchantSecuritySuccess = payload => ({
  type: CHANGE_MERCHANT_SECURITY_SUCCESS,
  payload,
});

const changeMerchantSecurityFail = payload => ({
  type: CHANGE_MERCHANT_SECURITY_FAIL,
  payload,
});

export const setMerchantDataTwoFactorToken = payload => ({
  type: SET_MERCHANT_SECURITY_TWO_FACTOR_TOKEN,
  payload,
});

export const setMerchantDataEmail = payload => ({
  type: SET_MERCHANT_SECURITY_EMAIL,
  payload,
});

export const setMerchantDataVerifyCode = payload => ({
  type: SET_MERCHANT_SECURITY_VERIFY_CODE,
  payload,
});

// ASYNC ACTIONS

//--GET CHECKOUTS LIST
export const getCheckoutList = data => async dispatch => {
  dispatch(getCheckoutListRequest());
  try {
    const response = await cryptoApi.getCheckoutsList(data.merchantId, data.params);
    dispatch(getCheckoutListSuccess(response));
  } catch (error) {
    dispatch(getCheckoutListFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--CREATE CHECKOUT DONATION
export const createCheckoutDonation = data => async dispatch => {
  dispatch(createCheckoutDonationRequest());
  try {
    const response = await cryptoApi.createCheckoutDonation(data.merchantId, data.body);
    dispatch(createCheckoutDonationSuccess(response));
    dispatch(notificationsSuccessShow({}));
    dispatch(getCheckoutList({ merchantId: data.merchantId, params: { page: 1, limit: 5, order: 'DESC' } }));
  } catch (error) {
    dispatch(createCheckoutDonationFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--CREATE CHECKOUT SALE
export const createCheckoutSale = data => async dispatch => {
  dispatch(createCheckoutSaleRequest());
  try {
    const response = await cryptoApi.createCheckoutSale(data.merchantId, data.body);
    dispatch(createCheckoutSaleSuccess(response));
    dispatch(notificationsSuccessShow({}));
    dispatch(getCheckoutList({ merchantId: data.merchantId, params: { page: 1, limit: 5, order: 'DESC' } }));
  } catch (error) {
    dispatch(createCheckoutSaleFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--CREATE CHECKOUT SELL CURRENCY
export const createCheckoutSellCurrency = data => async dispatch => {
  dispatch(createCheckoutSellCurrencyRequest());
  try {
    const response = await cryptoApi.createCheckoutSellCurrency(data.merchantId, data.body);
    dispatch(createCheckoutSellCurrencySuccess(response));
    dispatch(notificationsSuccessShow({}));
    dispatch(getCheckoutList({ merchantId: data.merchantId, params: { page: 1, limit: 5, order: 'DESC' } }));
  } catch (error) {
    dispatch(createCheckoutSellCurrencyFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--CREATE CHECKOUT CART
export const createCheckoutCart = data => async dispatch => {
  dispatch(createCheckoutCartRequest());
  try {
    const response = await cryptoApi.createCheckoutCart(data.merchantId, data.body);
    dispatch(createCheckoutCartSuccess(response));
    dispatch(notificationsSuccessShow({}));
    dispatch(getCheckoutList({ merchantId: data.merchantId, params: { page: 1, limit: 5, order: 'DESC' } }));
  } catch (error) {
    dispatch(createCheckoutCartFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--DELETE CHECKOUT
export const deleteCheckout = (data, page) => async dispatch => {
  dispatch(deleteCheckoutRequest());
  try {
    await cryptoApi.deleteCheckout(data.merchantId, data.checkoutId);
    dispatch(deleteCheckoutSuccess(data.checkoutId));
    dispatch(notificationsSuccessShow({}));
    dispatch(getCheckoutList({ merchantId: data.merchantId, params: { page: page, limit: 5, order: 'DESC' } }));
  } catch (error) {
    dispatch(deleteCheckoutFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--PATCH CHECKOUT
export const patchCheckout = data => async dispatch => {
  dispatch(patchCheckoutRequest());
  try {
    const response = await cryptoApi.patchCheckout(data);
    dispatch(patchCheckoutSuccess(response));
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(patchCheckoutFail());
    dispatch(notificationsErrorShow(error.data));
    throw error.data;
  }
};

//--SET DETAILED CHECKOUT
export const setDetailedCheckout = data => dispatch => {
  dispatch(setDetailed(data));
};

export const setEditCheckout = data => dispatch => {
  dispatch(setEdit(data));
};

// GET SUPPORTED CURRENCIES LIST

export const getSupportedCurrenciesList = () => async dispatch => {
  dispatch(getSupportedCurrenciesListRequest());
  try {
    const response = await cryptoApi.getSupportedCurrenciesList();
    dispatch(getSupportedCurrenciesListSuccess(response?.data));
  } catch (error) {
    dispatch(getSupportedCurrenciesListFail(error.data));
  }
};

// GET SUPPORTED WALLETS LIST

export const getSupportedWalletsList = (typeNetwork, merchantId) => async dispatch => {
  dispatch(getSupportedWalletsListRequest());
  try {
    const response = await cryptoApi.getSupportedWalletsList(typeNetwork, merchantId);
    dispatch(getSupportedWalletsListSuccess(response?.data));
  } catch (error) {
    dispatch(getSupportedWalletsListFail(error?.data));
  }
};

// GET PLANS LIST

export const getPlansList = data => async dispatch => {
  dispatch(getPlansListRequest());
  try {
    const response = await cryptoApi.getPlansList(data.merchantId, data.params);
    dispatch(getPlansListSuccess(response?.data));
  } catch (error) {
    dispatch(getPlansListFail(error?.data));
  }
};

// CREATE NEW PLAN

export const createNewPlan = data => async dispatch => {
  dispatch(createNewPlanRequest());
  try {
    const response = await cryptoApi.createNewPlan(data.merchantId, data.body);
    dispatch(createNewPlanSuccess(response?.data));
    dispatch(notificationsSuccessShow({}));
    dispatch(getPlansList({
      merchantId: data.merchantId,
      params: {
        typeNetwork: data.typeNetwork,
        page: data?.page,
        limit: 5,
        order: 'DESC'
      }
    }));
  } catch (error) {
    dispatch(createNewPlanFail(error?.data));
    dispatch(notificationsErrorShow(error.data));
  }
};

// DELETE PLAN

export const deletePlan = (page, planId, merchantId, typeNetwork) => async dispatch => {
  try {
    const response = await cryptoApi.deletePlan(planId, merchantId);
    dispatch(notificationsSuccessShow({}));
    dispatch(getPlansList({
      merchantId,
      params: {
        typeNetwork,
        page,
        limit: 5,
        order: 'DESC'
      }
    }));
  } catch (error) {
    dispatch(getPlansList({
      merchantId,
      params: {
        typeNetwork,
        page,
        limit: 5,
        order: 'DESC'
      }
    }));
    dispatch(notificationsErrorShow(error.data));
  }
};

// PATCH PLAN

export const patchPlan = data => async dispatch => {
  dispatch(patchPlanRequest());
  try {
    const response = await cryptoApi.patchPlan(data?.planId, data.merchantId, data.body);
    dispatch(getPlansList({
      merchantId: data.merchantId,
      params: {
        typeNetwork: data.typeNetwork,
        page: data.page,
        limit: 5,
        order: 'DESC'
      }
    }));
    dispatch(notificationsSuccessShow({}));
    dispatch(patchPlanSuccess(response?.data));
  } catch (error) {
    dispatch(patchPlanFail(error?.data));
    dispatch(notificationsErrorShow(error.data));
  }
};

export const publishPlan = (page, typeNetwork, planId, merchantId) => async dispatch => {
  try {
    const response = await cryptoApi.publishPlan(planId, merchantId);
    dispatch(getPlansList({
      merchantId,
      params: {
        typeNetwork,
        page,
        limit: 5,
        order: 'DESC'
      }
    }));
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET SUBSCRIPTIONS

export const getSubscriptions = (merchantId, params) => async dispatch => {
  dispatch(getSubscriptionsRequest());
  try {
    const response = await cryptoApi.getSubscriptions(merchantId, params);
    dispatch(getSubscriptionsSuccess(response?.data));
  } catch (error) {
    dispatch(getSubscriptionsFail(error?.data));
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET ACTIVITIES

export const getActivities = (merchantId, params) => async dispatch => {
  dispatch(getActivitiesRequest());
  try {
    const response = await cryptoApi.getActivities(merchantId, params);
    dispatch(getActivitiesSuccess(response?.data));
  } catch (error) {
    dispatch(getActivitiesFail(error?.data));
    dispatch(notificationsErrorShow(error.data));
  }
};

// CANCEL SUBSCRIPTION

export const cancelSubscription = (subscriptionId) => async dispatch => {
  dispatch(cancelSubscriptionRequest());

  try {
    const response = await cryptoApi.cancelSubscriptions(subscriptionId);
    dispatch(cancelSubscriptionSuccess(response?.data));
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(cancelSubscriptionFail(error?.data));
    dispatch(notificationsErrorShow(error.data));
  }
}

// GET MERCHANT SECURITY

export const getMerchantSecurity = dispatch => async merchantId => {
  dispatch(getMerchantSecurityRequest());
  try {
    const response = await cryptoApi.getMerchantSecurity(merchantId);
    dispatch(getMerchantSecuritySuccess(response?.data));
  } catch (error) {
    dispatch(getMerchantSecurityFail(error?.data));
    dispatch(notificationsErrorShow(error.data));
  }
};

// CHANGE MERCHANT SECURITY

export const changeMerchantSecurity = dispatch => async (merchantId, body) => {
  dispatch(changeMerchantSecurityRequest());
  let response;
  try {
    response = await cryptoApi.changeMerchantSecurity(merchantId, body);
  } catch (error) {
    dispatch(changeMerchantSecurityFail(error?.data));
    dispatch(notificationsErrorShow(error.data));
  }

  if (response && response?.data?.twoFactorToken) {
    dispatch(setMerchantDataTwoFactorToken(true));
    return;
  }

  if (response && response?.data?.emailSent) {
    dispatch(setMerchantDataEmail(true));
    return;
  }

  if (response && response?.data?.codeSent) {
    dispatch(setMerchantDataVerifyCode(true));
    return;
  }

  if (response && response.data) {
    dispatch(changeMerchantSecuritySuccess(true));
    dispatch(notificationsSuccessShow({}));
    return response;
  }
};
