import React, { useState, useEffect } from 'react';
import { identity } from "../../../utils/getIdentity";
import { Input, Modal } from "antd";
import '../../../layout/Modal.scss';
import '../WalletDeleteModal.scss';
import Button from "../../Button";
import {
  createCurrencyFail,
  approveCurrencyFail,
  addTokenCloseModal,
  createCurrency, approveCreateCurrency
} from "../../../redux/actions/currencies";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import tickCircleIcon from "../../../img/default-svg/tickCircle.svg";
import TokenIcon from "../../TokenIcon";

const AddCustomTokenWallet = ({
  isOpenModal,
  createCurrency,
  requestId,
  tokenMetadata,
  tokenFetching,
  approveCreateCurrency,
  isApprovedFetching,
  isApprovedToken
}) => {
  const [contractAddress, setContractAddress] = useState("");
  const [typeNode, setTypeNode] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const createCurrencyRequest = () => {
    createCurrency({ typeNode, contractAddress });
  };

  const approveCreateCurrencyRequest = () => {
    approveCreateCurrency(requestId)
  }

  const handleClose = () => {
    setContractAddress('');
    setTypeNode('');
    dispatch(createCurrencyFail());
    dispatch(addTokenCloseModal());
  };

  const acceptedNode = [
    {
      title: 'Tron',
      shortName: 'trx'
    },
    {
      title: 'Solana',
      shortName: 'solana'
    },
    {
      title: 'ETH',
      shortName: 'eth'
    },
    {
      title: 'Ton',
      shortName: 'ton'
    }
  ]

  useEffect(() => {
    if (isApprovedToken) {
      handleClose();
      dispatch(approveCurrencyFail());
    }
  }, [isApprovedToken]);

  return (
    <Modal
      title={t('addToken.addCustomToken')}
      visible={isOpenModal}
      onCancel={handleClose}
      footer={null}
      className={`modal modal-${identity}`}
    >
      <div className="adminData">
        <div className="adminData__label">Select network</div>
        <div className="grid" style={{ marginBottom: '20px' }}>
          {acceptedNode.map((item) => (
            <div
              className={`grid__item ${item?.shortName === typeNode && "grid__item--selected"}`}
              onClick={() => setTypeNode(item?.shortName)}
            >
              {item?.shortName === typeNode && (
                <img src={tickCircleIcon} className="grid__tick" alt="selected" />
              )}
              <TokenIcon tokenName={item?.shortName} className="grid__logo" />
              <div className="grid__title">{item?.title}</div>
            </div>
          ))}
        </div>
        <div className="adminData__label">{t('addToken.contractAddress')}</div>
        <Input
          value={contractAddress}
          className="form__input"
          placeholder={t("addToken.enterContractAddress")}
          onChange={e => setContractAddress(e.target.value.trim())}
        />

        {tokenMetadata && (
          <div className="adminData__metadata">
            <div>
              <span>{t("addToken.name")}</span>
              <span>{tokenMetadata?.name}</span>
            </div>
            <div>
              <span>{t("addToken.symbol")}</span>
              <span>{tokenMetadata?.symbol}</span>
            </div>
            <div>
              <span>{t("addToken.decimals")}</span>
              <span>{tokenMetadata?.decimals}</span>
            </div>
          </div>
        )}

        <div  className="adminData__warning">
          * {t("addToken.warning")}
        </div>

        <div className="adminData--button">
          <Button
            type="primary"
            className={`modal__button modal__button-create modal__button modal__button-create-${identity} modal__button-create=${identity}`}
            onClick={!requestId ? createCurrencyRequest : approveCreateCurrencyRequest}
            disabled={contractAddress?.length < 10 || !typeNode}
            loading={(!requestId && tokenFetching) || (requestId && isApprovedFetching)}
          >
            {!requestId ? 'Import' : t('addToken.approve')}
          </Button>
        </div>
      </div>
    </Modal>
  )
};

const mapStateToProps = state => ({
  requestId: state.currencies.token.requestId,
  tokenMetadata: state.currencies.token.tokenMetadata,
  tokenFetching: state.currencies.token.fetching,
  isApprovedToken: state.currencies.isApproved.created,
  isApprovedFetching: state.currencies.isApproved.fetching,
  isOpenModal: state.currencies.isOpenModal
});

const mapDispatchToProps = dispatch => ({
  createCurrency: createCurrency(dispatch),
  approveCreateCurrency: approveCreateCurrency(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomTokenWallet);