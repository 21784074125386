import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { notification, Empty, Row, Col } from "antd";
import { formValueSelector } from 'redux-form';
import { useTranslation } from 'react-i18next';
import {
  getMerchantWallets,
  getMoreMerchantWallets,
  deleteMerchantWallet,
  resetPageCounter,
  setWalletAsMain,
  resetTwoFaState,
  getWalletPrivateKey,
  getWalletMnemonicPhrase
} from '../../redux/actions/merchantWallets';
import { useLocation } from 'react-router-dom';
import { getMerchantBalances, getMerchantData } from '../../redux/actions/merchantData';
import WalletsFilters from './WalletsFilters';
import WalletsPagination from './WalletsPagination';
import ReplenishModal from '../ReplenishModal/ReplenishModal';
import PrivateKeyModal from './PrivateKeyModal';
import WalletItem from "./WalletItem";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './WalletsList.scss';
import { getMoreTransactions, getTransactions } from '../../redux/actions/transactions';
import {
  checkAutosignStatus, checkPasswordStatus,
  downloadAutosign,
  getWithdrawWallets,
  setPasswordSignature
} from "../../redux/actions/withdraw";
import useWindowSize from "../../utils/resizeHook";

const WalletsList = ({
  formName,
  merchantId,
  networkFilter,
  wallets,
  typeWallet,
  currencies,
  getMerchantWallets,
  getMoreMerchantWallets,
  page,
  pages,
  countItem,
  resetPageCounter,
  walletsFilters,
  fetching,
  getMerchantData,
  merchantData,
  deleteMerchantWallet,
  setWalletAsMain,
  resetTwoFaState,
  getWalletPrivateKey,
  getWalletMnemonicPhrase,
  getMerchantBalances,
  getWithdrawWallets,
  autosignStep,
  downloadAutosignStep,
  downloadAutosign,
  setPasswordSignature,
  autosignStatus,
  checkAutosignStatus,
  checkPasswordStatus,
  passwordStatus,
  downloadError
}) => {
  const [socket, setSocket] = useState(undefined);
  const [walletId, setWalletId] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [walletCurrency, setWalletCurrency] = useState('');
  const [isReplenishModalOpen, setIsReplenishModalOpen] = useState(false);
  const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const { t } = useTranslation();
  const [showWalletBy, setShowWalletBy] = useState(null);
  const [isSegwit, setIsSegwit] = useState(false);
  const [hideZeroCheckbox, setHideZeroCheckbox] = useState(
    JSON.parse(localStorage.getItem('cpayHideZeroBalancesClient')) || false
  )

  const { currentWidth } = useWindowSize();

  const defaultFilterValues = {
    order: 'DESC',
    search: '',
    limit: 10,
    currencyIds:
      typeWallet === 'merchant'
        ? currencies.filter(currency => currency.currencyType === 'currency').map(currency => currency._id)
        : currencies.map(currency => currency._id),
    typeNetwork: networkFilter,
  };

  const { pathname } = useLocation();
  const isFromMerchantsWallets = pathname.includes('/merchants/wallets') || pathname === '/wallets';

  useEffect(() => {
    if (merchantId) {
      getMerchantData(merchantId);
    }
  }, [merchantId]);


  useEffect(() => {
    if (
      Object.keys(walletsFilters).length !== 0 &&
      walletsFilters.constructor === Object &&
      merchantId &&
      merchantId.length
    ) {
      if (walletsFilters.limit || walletsFilters.order) {
        submitFilters();
      }
    }
  }, [merchantId, walletsFilters.limit, walletsFilters.order, networkFilter]);

  const handleReplenishButton = (address, currency, isModalOpen) => {
    setWalletAddress(address);
    setWalletCurrency(currency);
    setIsReplenishModalOpen(isModalOpen);
  };

  const openNotification = () => {
    notification.success({
      message: t('copied'),
      duration: 2,
    });
  };

  const openModal = walletId => {
    setWalletId(walletId);
    setIsKeyModalOpen(true);
  };

  const closeModal = () => {
    setIsKeyModalOpen(false);
    resetTwoFaState(false);
  };

  const loadMoreWallets = page => {
    const { order, limit, search, currencyIds } = walletsFilters;
    getMoreMerchantWallets(merchantId, {
      order: order,
      limit: limit,
      search: search.trim(),
      currencyIds: currencyIds.join(',') || undefined,
      typeWallet: typeWallet,
      page: page,
      typeNetwork: networkFilter,
      showTokens: false,
      positiveBalance: hideZeroCheckbox
    });
  };

  const updateList = () => {
    submitFilters()
  }

  const updateAfterChangeSegwit = () => {
    const { order, limit, search, currencyIds } = walletsFilters;
    resetPageCounter();
    getMerchantWallets(merchantId, {
      order: order,
      limit: limit,
      search: search.trim(),
      currencyIds: currencyIds.join(',') || undefined,
      typeWallet: typeWallet,
      page: page,
      typeNetwork: networkFilter,
      showTokens: false,
      positiveBalance: hideZeroCheckbox
    }).then(() => setIsFetched(true));
  }

  useEffect(() => {
    if (isSegwit) {
      updateAfterChangeSegwit()
    }

    setIsSegwit(false);
  }, [isSegwit])

  const submitFilters = () => {
    const { order, limit, search, currencyIds } = walletsFilters;
    resetPageCounter();
    getMerchantWallets(merchantId, {
      order: order,
      limit: limit,
      search: search.trim(),
      currencyIds: currencyIds.join(',') || undefined,
      typeWallet: typeWallet,
      page: 1,
      typeNetwork: networkFilter,
      showTokens: false,
      positiveBalance: hideZeroCheckbox
    }).then(() => setIsFetched(true));
  };

  const resetFilters = () => {
    const { currencyIds } = defaultFilterValues;
    getMerchantWallets(merchantId, {
      ...defaultFilterValues,
      currencyIds: currencyIds.join(',') || undefined,
      typeWallet: typeWallet,
      page: 1,
      typeNetwork: networkFilter,
      showTokens: false,
      positiveBalance: hideZeroCheckbox
    }).then(() => setIsFetched(true));
  };

  useEffect(() => {
    socket && socket.disconnect();
    setSocket(undefined);
    createSocket(merchantId);
  }, [merchantId]);

  const createSocket = merchantId => {
    const io = window.io;
    const socket = io(process.env.REACT_APP_API_URL, {
      allowEIO3: true,
      withCredentials: true,
    });
    socket.on('connect', () => {
      socket.emit('sign-in-merchant', { token: localStorage.getItem('authToken'), merchantId });
      setSocket(socket);
    });
    socket.on('updatedBalance', () => {
     // const { currencyIds } = walletsFilters;

      if (merchantId) {
      //  getMerchantBalances(merchantId, networkFilter, true)
      //  getMerchantWallets(merchantId, {
      //   ...defaultFilterValues,
      //   currencyIds: currencyIds.join(',') || undefined,
      //   typeWallet: typeWallet,
      //   page: 1,
      //   typeNetwork: networkFilter,
      //   showTokens: false,
      // }, true).then(() => setIsFetched(true));
      // getWithdrawWallets(merchantId, currencyIds.join(',') || undefined, networkFilter, true, true);
      }
    });
    socket.on('disconnect', () =>{});
  };

  return (
    <React.Fragment>
      <ReplenishModal
        walletAddress={walletAddress}
        walletCurrency={walletCurrency}
        isReplenishModalOpen={isReplenishModalOpen}
        handleReplenishButton={handleReplenishButton}
      />
      <PrivateKeyModal
        merchantId={merchantId}
        walletId={walletId}
        setWalletId={setWalletId}
        isKeyModalOpen={isKeyModalOpen}
        closeModal={closeModal}
        openNotification={openNotification}
        getWalletPrivateKey={getWalletPrivateKey}
        showWalletBy={showWalletBy}
        getWalletMnemonicPhrase={getWalletMnemonicPhrase}
      />
      {currencies && currencies.length && (
        <WalletsFilters
          formName={formName}
          typeWallet={typeWallet}
          title={typeWallet === 'user' ? 'Clients Merchant' : 'Merchant'}
          currencies={currencies}
          merchantName={merchantData.name}
          initialValues={defaultFilterValues}
          submitFilters={submitFilters}
          resetFilters={resetFilters}
          fetching={fetching}
          hideZeroCheckbox={hideZeroCheckbox}
          setCheckbox={setHideZeroCheckbox}
        />
      )}
      {isFetched && wallets && wallets.length > 0 ? (
        <>
          <div className={`wallets-page ${fetching ? 'wallets-page_fetching' : ''}`}>
            {wallets.map((wallet, index) => {
              return (
                <>
                  {index === 0 && (
                    <Row align="middle">
                      <Col span={1}>
                      </Col>
                      <Col sm={{ span: currentWidth >= 1024 ? 1 : 2, offset: 0 }} xs={{ span: 3, offset: 1 }}>
                      </Col>
                      <Col sm={{ span: currentWidth >= 1024 ? 7 : 8, offset: 0 }} xs={{ span: 17 }}>
                      </Col>
                      <Col sm={{ span: currentWidth >= 1024 ? 2 : 7, offset: 1 }} xs={{ span: 11, offset: 5 }}>
                      </Col>
                      <Col sm={{ span: currentWidth >= 1024 ? 2 : 5, offset: 0 }} xs={{ span: 8 }}>
                      </Col>
                      <Col
                        sm={{ span: currentWidth >= 1024 ? 3 : 10, offset: currentWidth >= 1024 ? 0 : 0 }}
                        xs={{ span: 10, offset: 5 }}
                      >
                      </Col>
                      {wallet.typeWallet === 'merchant' && (
                        <Col
                          sm={{ span: currentWidth >= 1024 ? 1 : 1, offset: currentWidth >= 1024 ? 1 : 2 }}
                          xs={{ span: 10, offset: 5 }}
                          style={{ marginLeft: '2%', textAlign: 'center', maxWidth: 'auto !important' }}
                        >
                          {t('merchants.security.autosignStatus')}
                        </Col>
                      )}
                      {wallet.typeWallet === 'merchant' && (
                        <Col
                          sm={{ span: currentWidth >= 1024 ? 1 : 1, offset: currentWidth >= 1024 ? 1 : 2 }}
                          xs={{ span: 10, offset: 5 }}
                          style={{ marginLeft: '4%', textAlign: 'center', maxWidth: 'auto !important' }}
                        >
                          {t('merchants.security.passwordStatus')}
                        </Col>
                      )}
                      <Col
                        sm={{ span: currentWidth >= 1024 ? 2 : 2, offset: currentWidth >= 1024 ? 0 : 6 }}
                        xs={{ span: 4, offset: 1 }}
                      >
                      </Col>
                      <Col
                        sm={{ span: currentWidth >= 1024 ? 1 : 1, offset: currentWidth >= 1024 ? 0 : 1 }}
                        xs={{ span: 1, offset: 1 }}
                      >
                      </Col>
                    </Row>
                  )}
                  <WalletItem
                    autosignStep={autosignStep}
                    downloadAutosignStep={downloadAutosignStep}
                    key={wallet._id}
                    merchantId={merchantId}
                    wallet={wallet}
                    typeWallet={typeWallet}
                    page={page}
                    pages={pages}
                    countItem={countItem}
                    downloadAutosign={downloadAutosign}
                    walletsFilters={walletsFilters}
                    openModal={openModal}
                    setPasswordSignature={setPasswordSignature}
                    autosignStatus={autosignStatus}
                    openNotification={openNotification}
                    deleteMerchantWallet={deleteMerchantWallet}
                    handleReplenishButton={handleReplenishButton}
                    setWalletAsMain={setWalletAsMain}
                    checkAutosignStatus={checkAutosignStatus}
                    checkPasswordStatus={checkPasswordStatus}
                    passwordStatus={passwordStatus}
                    currencies={currencies}
                    networkFilter={networkFilter}
                    getWalletPrivateKey={getWalletPrivateKey}
                    getWalletMnemonicPhrase={getWalletMnemonicPhrase}
                    setShowWalletBy={setShowWalletBy}
                    isFromMerchantsWallets={isFromMerchantsWallets}
                    resetTwoFaState={resetTwoFaState}
                    updateList={updateList}
                    downloadError={downloadError}
                    setIsSegwit={setIsSegwit}
                  />
                </>
              );
            })}
          </div>
          {!fetching && page && pages ? (
            <WalletsPagination
              page={page}
              pages={pages}
              walletsFilters={walletsFilters}
              loadMoreWallets={loadMoreWallets}
              submitFilters={submitFilters}
              formName={formName}
            />
          ) : null}
        </>
      ) : (
        !fetching && <Empty description={t('nodata')} />
      )}

      {fetching && (
        <div className="wallets__loader">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  wallets: state.merchantWallets.data,
  page: state.merchantWallets.page,
  pages: state.merchantWallets.pages,
  countItem: state.merchantWallets.countItem,
  fetching: state.merchantWallets.fetching,
  merchantData: state.merchantData.data,
  currencies: state.currencies.data,
  walletsFilters: formValueSelector(props.formName)(state, 'search', 'order', 'limit', 'currencyIds'),
  merchantId: state.transactions.merchantId,
  autosignStep: state.withdraw.autosignStep,
  downloadAutosignStep: state.withdraw.downloadAutosignStep,
  downloadError: state.withdraw.downloadError,
  autosignStatus: state.withdraw.autosignStatus,
  passwordStatus: state.withdraw.passwordStatus,
});

const mapDispatchToProps = dispatch => ({
  getMerchantWallets: getMerchantWallets(dispatch),
  getMoreMerchantWallets: getMoreMerchantWallets(dispatch),
  getMerchantData: getMerchantData(dispatch),
  deleteMerchantWallet: deleteMerchantWallet(dispatch),
  setWalletAsMain: setWalletAsMain(dispatch),
  resetTwoFaState: resetTwoFaState(dispatch),
  getWalletPrivateKey: getWalletPrivateKey(dispatch),
  resetPageCounter,
  getWalletMnemonicPhrase: getWalletMnemonicPhrase(dispatch),
  getTransactions: getTransactions(dispatch),
  getMoreTransactions: getMoreTransactions(dispatch),
  getMerchantBalances: getMerchantBalances(dispatch),
  getWithdrawWallets: getWithdrawWallets(dispatch),
  downloadAutosign: downloadAutosign(dispatch),
  setPasswordSignature: setPasswordSignature(dispatch),
  checkAutosignStatus: checkAutosignStatus(dispatch),
  checkPasswordStatus: checkPasswordStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletsList);
