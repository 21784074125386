import qs from 'qs';
import fetchData from '../fetch';
import merchant from '../components/Merchant/Merchant';

class CryptoPayApi {
  async login(email, password, isAppTwoFa, emailTwoFaKey, verifyCode, recaptcha) {
    const response = await fetchData(`user/login`, {
      headers: {
        recaptcha,
      },
      body: {
        email,
        password,
        twoFactorToken: isAppTwoFa ? isAppTwoFa : undefined,
        emailOtp: emailTwoFaKey ? emailTwoFaKey : undefined,
        verifyCode: verifyCode ? verifyCode : undefined,
      },
    });
    return response;
  }

  loginWithSocials = async (accessToken, network, role) => {
    const response = await fetchData('user/social', {
      body: {
        accessToken,
        network: network,
        roles: [role],
      },
    });

    return response;
  };

  loginWithMetamask = async (address, signature, roles) => {
    const response = await fetchData('user/metamask', {
      body: {
        address,
        signature,
        roles,
      },
    });

    return response;
  };

  loginByPhone = async (phone, password, isAppTwoFa, emailTwoFaKey, verifyCode, recaptcha) => {
    const response = await fetchData('user/login-by-phone', {
      headers: {
        recaptcha,
      },
      body: {
        phone,
        password,
        twoFactorToken: isAppTwoFa ? isAppTwoFa : undefined,
        emailOtp: emailTwoFaKey ? emailTwoFaKey : undefined,
        verifyCode,
      },
    });
    return response;
  };

  logout = async () => {
    const response = await fetchData(`user/logout`, {
      method: 'POST',
    });
    return response;
  };

  signUpByPhone = async (body, recaptcha) => {
    const response = await fetchData('user/register-by-phone', {
      headers: {
        recaptcha,
      },
      body,
    });
    return response;
  };

  getTransactions = async (merchantId, params) => {
    const response = await fetchData(`transaction/${merchantId}/list?${qs.stringify(params)}`);
    return response;
  };

  downloadTransactionsInfo = async (body, merchantId) => {
    const response = await fetchData(`transaction/${merchantId}/list/export?${qs.stringify(body)}`, {
      isFormData: false,
      method: 'GET',
    });
    return response;
  };

  downloadAdminTransactionsInfo = async body => {
    const response = await fetchData(`transaction/list/admin/export?${qs.stringify(body)}`, {
      method: 'GET',
    });
    return response;
  };

  downloadAdminUserTransactionsInfo = async (userId, body) => {
    const response = await fetchData(`transaction/${userId}/list/admin/export?${qs.stringify(body)}`, {
      method: 'GET',
    });
    return response;
  };

  getMainWallets = async merchantId => {
    const response = await fetchData(`wallet/${merchantId}/main`);
    return response;
  };

  getMerchantWallets = async (merchantId, params, positiveBalance) => {
    const response = await fetchData(`wallet/${merchantId}?${qs.stringify({ ...params, positiveBalance })}`);
    return response;
  };

  getUser = async () => {
    const response = await fetchData(`user/profile`);
    return response;
  };

  editUser = async body => {
    const response = await fetchData(`user/profile`, {
      method: 'PATCH',
      body,
    });
    return response;
  };

  verifyPhone = async body => {
    const response = await fetchData(`user/phone/verify`, {
      method: 'POST',
      body: body,
    });
    return response;
  };

  resendPhoneInApp = async () => {
    const response = await fetchData(`user/phone/resend`, {
      method: 'POST',
      body: { type: 'TwoFa' },
    });
    return response;
  };

  resendEmail2FA = async email => {
    const response = await fetchData(`user/email/resendCode`, {
      method: 'POST',
      body: { email: email },
    });
    return response;
  };

  loginResendPhoneCode = async phone => {
    const response = await fetchData(`user/phone/resendCode`, {
      method: 'POST',
      body: { phone, type: 'Login' },
    });
    return response;
  };

  resendPhoneChange = async () => {
    const response = await fetchData(`user/phone/resend`, {
      method: 'POST',
      body: { type: 'ChangePhone' },
    });
    return response;
  };

  changePassword = async body => {
    const response = await fetchData('user/profile/change-password', {
      body,
    });
    return response;
  };

  signUp = async (body, recaptcha) => {
    const response = await fetchData('user/register', {
      headers: {
        recaptcha,
      },
      body,
    });
    return response;
  };

  sendPassword = async (body, recaptcha) => {
    const response = await fetchData('user/forgotten', {
      headers: {
        recaptcha,
      },
      body,
    });
    return response;
  };

  forgottenPassword = async (token, body) => {
    const response = await fetchData(`user/forgotten/${token}`, {
      body,
    });
    return response;
  };

  resetPassword = async (body, recaptcha) => {
    const response = await fetchData('user/restore-from-backup', {
      headers: {
        recaptcha,
      },
      body,
    });
    return response;
  };

  resendPassword = async () => {
    const response = await fetchData('user/email/resend', {
      method: 'POST',
    });
    return response;
  };

  verifyEmail = async secretKey => {
    const response = await fetchData(`user/email/verify/${secretKey}`, {
      method: 'PATCH',
    });
    return response;
  };

  emailChangeVerify = async secretKey => {
    const response = await fetchData(`user/changeEmail/verify/${secretKey}`, {
      method: 'POST',
    });
    return response;
  };

  confirmBackup = async words => {
    const response = await fetchData('user/confirm-backup', {
      body: { words },
    });
    return response;
  };

  getMerchantsList = async () => {
    const response = await fetchData('merchant/list');
    return response;
  };

  getMerchantData = async merchantId => {
    const response = await fetchData(`merchant/${merchantId}`);
    return response;
  };

  getMerchantBalances = async (merchantId, networkFilter, positiveBalance) => {
    const response = await fetchData(
      `merchant/${merchantId}/balance?${qs.stringify({ typeNetwork: networkFilter, positiveBalance })}`
    );
    return response;
  };

  createMerchant = async body => {
    const response = await fetchData('merchant', {
      body,
    });
    return response;
  };

  editMerchantData = async (merchantId, body) => {
    const response = await fetchData(`merchant/${merchantId}`, {
      method: 'PATCH',
      body,
    });
    return response;
  };

  changeMerchantNetwork = async (merchantId, body) => {
    const response = await fetchData(`merchant/${merchantId}/network`, {
      method: 'PATCH',
      body,
    });
    return response;
  };

  deleteMerchant = async (merchantId, appTwoFaCode, emailTwoFaCode, verifyCode) => {
    const response = await fetchData(`merchant/${merchantId}`, {
      method: 'DELETE',
      body: {
        twoFactorToken: appTwoFaCode ? appTwoFaCode : undefined,
        emailOtp: emailTwoFaCode ? emailTwoFaCode : undefined,
        verifyCode: verifyCode ? verifyCode : undefined,
      },
    });
    return response;
  };

  getMerchantApiKeys = async (merchantId, emailTwoFaCode, appTwoFaCode, verifyCode) => {
    const response = await fetchData(`merchant/${merchantId}/api-key`, {
      method: 'POST',
      body: {
        emailOtp: emailTwoFaCode ? emailTwoFaCode : undefined,
        twoFactorToken: appTwoFaCode ? appTwoFaCode : undefined,
        verifyCode: verifyCode ? verifyCode : undefined,
      },
    });
    return response;
  };

  createMerchantWallet = async (merchantId, walletData) => {
    const response = await fetchData(`wallet/${merchantId}/create-wallet`, {
      body: walletData,
    });
    return response;
  };

  getWalletPrivateKey = async (merchantId, walletId, appTwoFaCode, emailTwoFaCode, verifyCode) => {
    const response = await fetchData(`wallet/private-key/${merchantId}/${walletId}`, {
      body: {
        emailOtp: emailTwoFaCode ? emailTwoFaCode : undefined,
        twoFactorToken: appTwoFaCode ? appTwoFaCode : undefined,
        verifyCode: verifyCode ? verifyCode : undefined,
      },
    });
    return response;
  };

  getWalletMnemonicPhrase = async (merchantId, walletId, appTwoFaCode, emailTwoFaCode, verifyCode) => {
    const response = await fetchData(`wallet/mnemonic/${merchantId}/${walletId}`, {
      body: {
        emailOtp: emailTwoFaCode ? emailTwoFaCode : undefined,
        twoFactorToken: appTwoFaCode ? appTwoFaCode : undefined,
        verifyCode: verifyCode ? verifyCode : undefined,
      },
    });
    return response;
  };

  getPassphrase = async (merchantId, walletId, appTwoFaCode, emailTwoFaCode, verifyCode) => {
    const response = await fetchData(`wallet/passphrase/${merchantId}/${walletId}`, {
      body: {
        emailOtp: emailTwoFaCode ? emailTwoFaCode : undefined,
        twoFactorToken: appTwoFaCode ? appTwoFaCode : undefined,
        verifyCode: verifyCode ? verifyCode : undefined,
      },
    });
    return response;
  };

  deleteMerchantWallet = async (merchantId, walletId, appTwoFaCode, emailTwoFaCode, verifyCode) => {
    const response = await fetchData(`wallet/${merchantId}/${walletId}`, {
      method: 'DELETE',
      body: {
        twoFactorToken: appTwoFaCode ? appTwoFaCode : undefined,
        emailOtp: emailTwoFaCode ? emailTwoFaCode : undefined,
        verifyCode: verifyCode ? verifyCode : undefined,
      },
    });
    return response;
  };

  setWalletAsMain = async (merchantId, walletId, currencyId) => {
    const response = await fetchData(`wallet/${merchantId}/${walletId}`, {
      method: 'PATCH',
      body: {
        currencyId,
        main: true,
      },
    });
    return response;
  };

  makeWithdraw = async (
    from,
    to,
    amount,
    merchantId,
    currencyToken,
    priority,
    comment,
    password,
    sign,
    appTwoFaCode,
    emailTwoFaCode,
    verifyCode
  ) => {
    const response = await fetchData(`withdrawal/${merchantId}/${from}`, {
      method: 'POST',
      body: {
        to,
        amount,
        currencyToken,
        priority,
        comment,
        password,
        sign,
        twoFactorToken: appTwoFaCode ? appTwoFaCode : undefined,
        emailOtp: emailTwoFaCode ? emailTwoFaCode : undefined,
        verifyCode: verifyCode ? verifyCode : undefined,
      },
    });
    return response;
  };

  withdrawalEstimateMax = async (merchantId, walletId, data) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/estimateMax`, {
      method: 'POST',
      body: data,
    });
    return response;
  };

  getWithdrawalInfo = async (merchantId, walletId) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/withdrawal-info`);
    return response;
  };

  getCurrencies = async merchantId => {
    const response = await fetchData(`currency?${qs.stringify({ merchantId, limit: 100 })}`);
    return response;
  };

  createCurrency = async body => {
    const response = await fetchData('currency/create/request', {
      method: 'POST',
      body
    });
    return response;
  };

  approveCreateCurrency = async requestId => {
    const response = await fetchData(`currency/request/${requestId}/approve`, {
      method: 'POST',
    });
    return response;
  }

  getCurrenciesRate = async () => {
    const response = await fetchData('currency/rate');
    return response;
  };

  getFeeEstimate = async (merchantId, walletId, to, amount, currencyToken, priority, password, sign, isSwap = false) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/estimate`, {
      method: 'POST',
      body: {
        to,
        amount,
        currencyToken,
        priority,
        password,
        sign,
        isSwap
      },
    });
    return response;
  };

  getFeeEstimateAdmin = async (walletId, to, amount, currencyToken, isSwap = false) => {
    const response = await fetchData(`withdrawal/admin/system-fee-wallet/${walletId}/estimate`, {
      method: 'POST',
      body: {
        to,
        amount,
        currencyToken,
        isSwap
      },
    });
    return response;
  };

  //---CHECKOUTS

  getCheckoutsList = async (merchantId, params) => {
    const response = await fetchData(`checkout/${merchantId}?${qs.stringify(params)}`);
    return response;
  };

  createCheckoutDonation = async (merchantId, body) => {
    const response = await fetchData(`checkout/${merchantId}/donation`, {
      method: 'POST',
      body: body,
      isFormData: true,
    });
    return response;
  };

  createCheckoutSale = async (merchantId, body) => {
    const response = await fetchData(`checkout/${merchantId}/sale`, {
      method: 'POST',
      body,
      isFormData: true,
    });
    return response;
  };

  createCheckoutSellCurrency = async (merchantId, body) => {
    const response = await fetchData(`checkout/${merchantId}/saleToken`, {
      method: 'POST',
      body,
      isFormData: true,
    });
    return response;
  };

  createCheckoutCart = async (merchantId, body) => {
    const response = await fetchData(`checkout/${merchantId}/cart`, {
      method: 'POST',
      body,
      isFormData: true,
    });
    return response;
  };

  deleteCheckout = async (merchantId, checkoutId) => {
    const response = await fetchData(`checkout/${merchantId}/${checkoutId}`, {
      method: 'DELETE',
    });
    return response;
  };

  patchCheckout = async data => {
    const response = await fetchData(`checkout/${data.merchantId}/${data.checkoutId}/${data.type}`, {
      method: 'PATCH',
      body: data.body,
      isFormData: true,
    });
    return response;
  };

  getEstimateMax = async (merchantId, body) => {
    const response = await fetchData(`checkout/${merchantId}/saleToken/estimateMax`, {
      body: body,
    });
    return response;
  };

  //---CHECKOUTS PAYMENTS

  getCheckoutData = async (identifier, recipient) => {
    const response = await fetchData(`checkout-client/${identifier}${recipient ? `?recipient=${recipient}` : ''}`);
    return response;
  };

  createChargeId = async (identifier, clickId) => {
    const response = await fetchData(`checkout-client/${identifier}/charge${clickId ? `?clickId=${clickId}` : ''}`, {
      method: 'POST',
    });
    return response;
  };

  createSakeTokenChargeId = async (identifier, params) => {
    const response = await fetchData(`checkout-client/${identifier}/saleTokenCharge?${qs.stringify(params)}`, {
      method: 'POST',
    });
    return response;
  };

  getChargeData = async (chargeId, params) => {
    const response = await fetchData(`checkout-client/${chargeId}/charge?${qs.stringify(params)}`);
    return response;
  };

  getSaleTokenChargeData = async chargeId => {
    const response = await fetchData(`checkout-client/${chargeId}/saleTokenCharge`);
    return response;
  };

  saveCustomerDetails = async (chargeId, body) => {
    const response = await fetchData(`checkout-client/${chargeId}/customerDetails`, {
      body,
    });
    return response;
  };

  getSwapHistory = async (merchantId, body) => {
    const response = await fetchData(`swap/${merchantId}/history?${qs.stringify(body)}`);
    return response;
  };

  getSwapOffers = async body => {
    const response = await fetchData(`swap/estimate?${qs.stringify(body)}`);
    return response;
  };

  makeSwap = async body => {
    const response = await fetchData(`swap`, { body });
    return response;
  };

  makeSwapSwing = async body => {
    const response = await fetchData('swap/dex/send', { body });
    return response;
  }

  getSwapTokens = async () => {
    const response = await fetchData('swap/tokens');
    return response;
  };

  makeStableSwap = async body => {
    const response = await fetchData(`swap/send`, { body });
    return response;
  };

  getReceiveSwap = async body => {
    const response = await fetchData(`swap/receive`, { body });
    return response;
  };

  getMultisendSystemFee = async (merchantId, walletId, body) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/multisend/estimateSystemFee`, { body });
    return response;
  };
  multisendApprove = async (merchantId, walletId, estimationId) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/multisend/approve`, {
      body: {
        estimationId,
      },
    });
    return response;
  };
  getMultisendMinerFee = async (merchantId, walletId, estimationId) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/multisend/estimateMinerFee`, {
      body: {
        estimationId,
      },
    });
    return response;
  };
  multisendWithdraw = async (merchantId, walletId, estimationId, password, sign, appTwoFa, emailTwoFa, verifyCode) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/multisend`, {
      body: {
        estimationId,
        password,
        sign,
        twoFactorToken: appTwoFa,
        emailOtp: emailTwoFa,
        verifyCode: verifyCode,
      },
    });
    return response;
  };
  nftEstimateWithdraw = async (merchantId, walletId, body) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/estimateNft`, { body });
    return response;
  };
  nftWithdraw = async (merchantId, walletId, body) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/nft`, { body });
    return response;
  };
  installMetamask = async (address, signature) => {
    const response = await fetchData(`user/installMetamask`, {
      body: {
        address,
        signature,
      },
    });
    return response;
  };
  removeMetamask = async (address, signature) => {
    const response = await fetchData(`user/removeMetamask`, {
      body: {
        address,
        signature,
      },
    });
    return response;
  };

  unsubscribeFromEmail = async (data) => {
    const response = await fetchData(`user/email/unsubscribe`, {
      method: 'POST',
      body: data
    });
    return response;
  };

  autoswapGet = async merchantId => {
    const response = await fetchData(`swap/${merchantId}/auto-swap-setting`);
    return response;
  };

  autoswapPost = async (merchantId, data) => {
    const response = await fetchData(`swap/${merchantId}/auto-swap-setting`, {
      body: data,
    });
    return response;
  };

  autoswapDelete = async (merchantId, settingId) => {
    const response = await fetchData(`swap/${merchantId}/auto-swap-setting/${settingId}`, {
      method: 'DELETE',
    });
    return response;
  };

  autoswapPatch = async (merchantId, settingId, data) => {
    const response = await fetchData(`swap/${merchantId}/auto-swap-setting/${settingId}`, {
      body: data,
      method: 'PATCH',
    });
    return response;
  };

  checkAutosignStatus = async (merchantId, walletId) => {
    const response = await fetchData(`wallet/signature/${merchantId}/${walletId}/status`);
    return response;
  };

  uploadNewAutosign = async (merchantId, walletId, data) => {
    const response = await fetchData(`wallet/signature/${merchantId}/${walletId}/on`, {
      body: data,
    });
    return response;
  };

  disableAutosign = async (merchantId, walletId, data) => {
    const response = await fetchData(`wallet/signature/${merchantId}/${walletId}/off`, {
      body: data,
    });
    return response;
  };

  setPasswordSignature = async (merchantId, walletId, data) => {
    const response = await fetchData(`wallet/signature/${merchantId}/${walletId}/change-password`, {
      body: data,
    });
    return response;
  };

  downloadAutosign = async (merchantId, walletId, data) => {
    const response = await fetchData(`wallet/signature/${merchantId}/${walletId}/download`, {
      body: data,
      isFormData: false,
      isFormText: true,
    });
    return response;
  };

  checkPasswordStatus = async (merchantId, walletId) => {
    const response = await fetchData(`wallet/signature/${merchantId}/${walletId}/password-status`);
    return response;
  };

  getSupportedCurrenciesList = async () => {
    const response = await fetchData('recurring-payment/support-currencies');
    return response;
  };

  getSupportedWalletsList = async (typeNetwork, merchantId) => {
    const response = await fetchData(
      `recurring-payment/support-wallets/${merchantId}?${qs.stringify({ typeNetwork: typeNetwork })}`
    );
    return response;
  };

  createNewPlan = async (merchantId, body) => {
    const response = await fetchData(`recurring-payment/plan/${merchantId}`, {
      method: 'POST',
      body,
      isFormData: true,
    });
    return response;
  };

  getPlansList = async (merchantId, params) => {
    const response = await fetchData(
      `recurring-payment/plan/${merchantId}?${qs.stringify(params)}`
    );
    return response;
  };

  deletePlan = async (planId, merchantId) => {
    const response = await fetchData(`recurring-payment/plan/${merchantId}/${planId}`, {
      method: 'DELETE',
    });
    return response;
  };

  patchPlan = async (planId, merchantId, body) => {
    const response = await fetchData(`recurring-payment/plan/${merchantId}/${planId}`, {
      method: 'PATCH',
      body,
      isFormData: true,
    });
    return response;
  };

  publishPlan = async (planId, merchantId) => {
    const response = await fetchData(`recurring-payment/plan/${merchantId}/${planId}/publish-changes`, {
      method: 'POST',
    });
    return response;
  };

  planEstimateMax = async (merchantId, data) => {
    const response = await fetchData(`recurring-payment/plan/${merchantId}/estimate`, {
      method: 'POST',
      body: data,
    });
    return response;
  }

  getSubscriptions = async (merchantId, params) => {
    const response = await fetchData(`recurring-payment/plan/${merchantId}/subscribers?${qs.stringify(params)}`);
    return response;
  };

  getActivities = async (merchantId, params) => {
    const response = await fetchData(`recurring-payment/plan/${merchantId}/activity?${qs.stringify(params)}`);
    return response;
  };

  cancelSubscriptions = async (subscriptionId) => {
    const response = await fetchData(`recurring-payment/subscription/${subscriptionId}`, {
      method: 'PATCH'
    });
    return response;
  };

  getMerchantSecurity = async merchantId => {
    const response = await fetchData(`merchant/${merchantId}/security`);
    return response;
  };

  changeMerchantSecurity = async (merchantId, body) => {
    const response = await fetchData(`merchant/${merchantId}/security`, {
      method: 'PATCH',
      body,
    });
    return response;
  };

  getWebhooksList = async (merchantId, params) => {
    const response = await fetchData(`merchant/${merchantId}/webhook?${qs.stringify(params)}`);
    return response;
  };

  getSwingEstimate = async body => {
    const response = await fetchData(`swap/dex/quotes?${qs.stringify(body)}`);
    return response;
  }

  changeToSegwit = async (merchantId, walletId) => {
    const response = await fetchData(`wallet/${merchantId}/${walletId}/segwit`, {
      method: 'PATCH'
    });
    return response;
  }
}

export const cryptoApi = new CryptoPayApi();
