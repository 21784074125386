import React from 'react';
import { identity } from "../../../utils/getIdentity";
import { Modal } from 'antd';
import '../../../layout/Modal.scss';
import Button from "../../Button";
import '../../WalletsPage/WalletDeleteModal.scss';

const CancelSubscriptionModal = ({ visible, close, fetching, handleCancelSubscription }) => {
  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={close}
      footer={null}
      className={`modal modal-${identity}`}
    >
      <div className="adminData">
        <div>Are you sure, you want to cancel this subscription?</div>

        <div className="adminData__buttons">
          <Button type="secondary" onClick={close}>
            No, Keep It
          </Button>
          <Button type="primary" loading={fetching} onClick={handleCancelSubscription}>
            Yes, Cancel
          </Button>
        </div>
      </div>
    </Modal>
)
};

export default CancelSubscriptionModal;