import React, { useState, useEffect } from 'react';
import { Input, Modal, Row, Col } from "antd";
import { ReactComponent as CloseSVG } from '../../../img/default-svg/close-black.svg';
import './style.scss';
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import ReactPaginate from 'react-paginate';
import moment from "moment/moment";
import { identity } from "../../../utils/getIdentity";
import resetCpayIcon from "../../../img/cpay-svg/reset.svg";
import resetNfgIcon from "../../../img/nfgpay-svg/reset-nfg.svg";
import resetFinvaroIcon from "../../../img/finvaro/reset-finvaro.svg";
import resetClarniumIcon from "../../../img/clarnium/reset-clarnium.svg";
import { ReactComponent as CheckIcon } from "../../../img/default-svg/tick.svg";
import { useLocation } from "react-router-dom";

const resetIcon = {
  cpay: resetCpayIcon,
  nfg: resetNfgIcon,
  finvaro: resetFinvaroIcon,
  clarnium: resetClarniumIcon,
};

const AddUserModal = ({ visible, close, getAdminUsers, usersList, usersCurrentPage, usersPages, rolesList, updateAdminUser, usersId }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [search, setSearch] = useState('');
  const [userId, setUserId] = useState('');
  const [roleId, setRoleId] = useState('');

  const handleGetUsers = (selectedPage, line = search) => {
    const pageNumber = selectedPage?.selected + 1;
    getAdminUsers({ search: line, page: pageNumber || 1 });
  }

  const handleClose = () => {
    setUserId('');
    setRoleId('');
    setSearch('');
    close();
  }

  const resetSearch = () => {
    setSearch('');
    handleGetUsers(1, '');
  };

  useEffect(() => {
    handleGetUsers(1);
  }, []);

  useEffect(() => {
    if (visible) {
      setUserId(usersId);
      if (location.pathname !== '/admin/users') {
        handleGetUsers(1);
      }
    }
  }, [visible]);

  const handleUpdate = () => {
    updateAdminUser(userId, roleId);
    handleClose();
  }

  return (
    <Modal
      width={900}
      title=""
      visible={visible}
      onCancel={handleClose}
      footer={null}
      className="checkout-modal"
      centered
      closeIcon={<CloseSVG />}
      destroyOnClose
      maskClosable={false}
    >
      <div className="role-modal">
        {!userId && (
          <div>
            <div className="role-modal__search">
              <Input.Search
                name="search"
                placeholder="Search"
                onSearch={() => getAdminUsers({ page: 1, search })}
                className="checkouts-header__search-field"
                onChange={e => setSearch(e.target.value)}
                value={search}
                addonAfter={
                  <span onClick={resetSearch} className="checkouts-header__reset-button">
                <img src={resetIcon[identity]} alt="Reset" />
              </span>
                }
              />
            </div>
            <Row className="role-modal__rowTitle">
              <Col span={5}>
                Name
              </Col>
              <Col span={7}>
                Email
              </Col>
              <Col span={6}>
                Date of registration
              </Col>
              <Col span={6}>
                Date of last login
              </Col>
            </Row>
            {usersList?.length > 0 && usersList?.map((item) => (
              <Row className="role-modal__rowContent" onClick={() => setUserId(item?.id)}>
                <Col span={5}>
                  {item?.name || '-'}
                </Col>
                <Col span={7}>
                  {item?.email || item?.phone || item?.metamask?.address || '-'}
                </Col>
                <Col span={6}>
                  {moment(item?.createdAt).format('MMMM D, YYYY, HH:mm;') || '-'}
                </Col>
                <Col span={6}>
                  {moment(item?.lastLogin).format('MMMM D, YYYY, HH:mm;') || '-'}
                </Col>
              </Row>
            ))}
            {usersPages > 1 && (
              <ReactPaginate
                pageCount={usersPages}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handleGetUsers}
                containerClassName="pagination"
                activeClassName="active"
                previousLabel={"«"}
                nextLabel={"»"}
                breakLabel={"..."}
                forcePage={usersCurrentPage - 1}
              />
            )}
          </div>
        )}

        {userId && (
          <div>
            {rolesList?.length && rolesList.map((item) => (
              <div className="admin-settings__role--card admin-settings__role--card-cursor" onClick={() => setRoleId(item?._id)}>
                <div
                  className="admin-settings__role--card-name">
                  {item?.name.charAt(0).toUpperCase() + item?.name.slice(1)}
                </div>
                <div>
                  {roleId === item?._id && <CheckIcon />}
                </div>
              </div>
            ))}

            <Button type="primary" disabled={!roleId} onClick={handleUpdate}>
              Confirm
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
};

export default AddUserModal;