import qs from 'qs';
import fetchData from '../fetch';

class AdminApi {
  getAdminSettings = async () => {
    const response = await fetchData(`setting`);
    return response;
  };

  updateAdminSettings = async body => {
    const response = await fetchData(`setting`, { body });
    return response;
  };

  getAdminUsers = async body => {
    const response = await fetchData(`user/list?${qs.stringify(body)}`);
    return response;
  };

  getAdminUserById = async userId => {
    const response = await fetchData(`user/profile/${userId}`);
    return response;
  };

  updateAdminUser = async (userId, body) => {
    const response = await fetchData(`user/profile/${userId}`, {
      method: 'PATCH',
      body,
    });
    return response;
  };

  createAdminNewUser = async body => {
    const response = await fetchData(`user/profile`, { body });
    return response;
  };

  getUserMerchants = async userId => {
    const response = await fetchData(`merchant/list?userId=${userId}`);
    return response;
  };

  updateMerchantsSystemFee = async (merchantId, systemFeePercent) => {
    const response = await fetchData(`setting/${merchantId}/systemFee`, {
      body: {
        systemFeePercent,
      },
    });
    return response;
  };

  updateMerchantsSystemFeeReplenish = async (merchantId, systemFeeReplenish) => {
    const response = await fetchData(`setting/${merchantId}/systemFeeReplenish`, {
      body: {
        systemFeePercent: systemFeeReplenish,
      },
    });
    return response;
  };

  updateMerchantsSystemFeeSwap = async (merchantId, systemFeePercent) => {
    const response = await fetchData(`setting/${merchantId}/systemFeeSwap`, {
      body: {
        systemFeePercent: systemFeePercent,
      },
    });
    return response;
  };

  updateMerchantsSystemFeeClient = async (merchantId, systemFeePercentClient) => {
    const response = await fetchData(`setting/${merchantId}/systemFeeClient`, {
      body: {
        systemFeePercent: systemFeePercentClient,
      }
    });
    return response;
  }

  updateMerchantsRefundFee = async (merchantId, refund) => {
    const response = await fetchData(`setting/${merchantId}/refund`, {
      body: {
        refund: refund,
      },
    });
    return response;
  };

  updateMerchantsMinimumWithdrawal = async (merchantId, minWithdrawal) => {
    const response = await fetchData(`setting/${merchantId}/minWithdrawal`, {
      body: {
        minWithdrawal: minWithdrawal,
      },
    });
    return response;
  };

  getAdminFeeWallets = async body => {
    const response = await fetchData(`wallet/admin/system-fee-wallet?${qs.stringify(body)}`);
    return response;
  };

  createAdminFeeWallets = async (currencyId, body) => {
    const response = await fetchData(`wallet/admin/system-fee-wallet/${currencyId}`, {
      body,
    });
    return response;
  };

  withdrawAdminSystemFee = async (walletId, body) => {
    const response = await fetchData(`withdrawal/admin/system-fee-wallet/${walletId}`, {
      body,
    });
    return response;
  };

  withdrawAdminSystemFeeEstimateMax = async (walletId, body) => {
    const response = await fetchData(`withdrawal/admin/system-fee-wallet/${walletId}/estimateMax`, {
      body,
    });
    return response;
  };

  getAdminFeeWalletsBalance = async body => {
    const response = await fetchData(`wallet/admin/system-fee-wallet/balance?${qs.stringify(body)}`);
    return response;
  };

  getAdminMerchantWallet = async (userId, merchantId, body) => {
    const response = await fetchData(`wallet/admin/${userId}/${merchantId}?${qs.stringify(body)}`);
    return response;
  }

  getFeeTransactions = async body => {
    const response = await fetchData(`withdrawal?${qs.stringify(body)}`);
    return response;
  };

  getUserTransactions = async body => {
    const response = await fetchData(`transaction/admin?${qs.stringify(body)}`);
    return response;
  };

  // DASHBOARD
  getCryptoCurrencyVolume = async data => {
    const response = await fetchData(`statistic/admin/cryptoCurrencyVolume?${qs.stringify(data)}`);
    return response;
  };

  getCryptoCurrencyQuantity = async data => {
    const response = await fetchData(`statistic/admin/cryptoCurrencyQuantityTx?${qs.stringify(data)}`);
    return response;
  };

  getNewUsers = async data => {
    const response = await fetchData(`statistic/admin/users?${qs.stringify(data)}`);
    return response;
  };

  getAdminTransactions = async data => {
    const response = await fetchData(`statistic/admin/transactions?${qs.stringify(data)}`);
    return response;
  };

  getCryptoCurrencyVolumeTrxByType = async data => {
    const response = await fetchData(`statistic/admin/cryptoCurrencyVolumeTxByType?${qs.stringify(data)}`);
    return response;
  };

  getTransactionsByUser = async body => {
    const response = await fetchData(`transaction/admin?${qs.stringify(body)}`);
    return response;
  };

  getUsersEmailsList = async body => {
    const response = await fetchData(`user/export?${qs.stringify(body)}`, { isFormData: false, method: 'POST' });
    return response;
  };

  downloadExcelFile = async filename => {
    const response = await fetchData('user/download', {
      method: 'POST',
      body: { fileName: filename }
    });
    return response;
  }

  getMarketingInfo = async body => {
    const response = await fetchData(`statistic/admin/marketing?${qs.stringify(body)}`, {
      isFormData: false,
      method: 'GET',
    });
    return response;
  };

  getCurrencyPriceById = async (currencyId, body) => {
    const response = await fetchData(`statistic/admin/cryptoCurrencyVolume/${currencyId}?${qs.stringify(body)}`, {
      isFormData: false,
      method: 'GET',
    });
    return response;
  };

  getAdminUserFeeTransactions = async data => {
    const response = await fetchData(`transaction/admin/total?${qs.stringify(data)}`);
    return response;
  };

  getRolesList = async () => {
    const response = await fetchData('role/admin/list');
    return response;
  };

  createNewRole = async (name) => {
    const response = await fetchData('role/admin', {
      method: 'POST' ,
      body: { name }
    });
    return response;
  };

  deleteRole = async (roleId) => {
    const response = await fetchData(`role/admin/${roleId}`, {
      method: 'DELETE',
    });
    return response;
  };

  getRolePermissions = async (roleId) => {
    const response = await fetchData(`role/admin/${roleId}/permissions`);
    return response;
  };

  changeRolePermissions = async (roleId, body) => {
    const response = await fetchData(`role/admin/${roleId}`, {
      method: 'PATCH',
      body
    });
    return response;
  };

  getUserLoginHistory = async (userId, params) => {
    const response = await fetchData(`user/profile/logins/${userId}?${qs.stringify(params)}`);
    return response;
  };

  getUsersLocations = async () => {
    const response = await fetchData('user/locations');
    return response;
  };

  getAdminSwapHistory = async (body) => {
    const response = await fetchData(`swap/admin/list?${qs.stringify(body)}`);
    return response;
  };

  makeAdminSwap = async body => {
    const response = await fetchData(`swap/admin`, { body });
    return response;
  };

  getAdminWalletData = async (type, userId, merchantId, walletId) => {
    const response = await fetchData(`wallet/admin/${type}/${userId}/${merchantId}/${walletId}`, {
      method: 'POST'
    });
    return response;
  }
}

export const adminApi = new AdminApi();
