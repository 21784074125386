import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Menu, Dropdown, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import WalletDeleteModal from './WalletDeleteModal';
import moment from 'moment';
import cn from 'classnames';
import TokenIcon from '../TokenIcon';
import formatCurrency from '../../utils/currencyFormatter';
import useWindowSize from '../../utils/resizeHook';
import { tokenNodeTypeObj, tokenTypeEnd } from '../../utils/tokenNodeTypes';
import './Wallet.scss';
import { ReactComponent as ArrowDownIcon } from '../../img/swap-svg/swap-select-arrow.svg';
import moreIcon from '../../img/default-svg/more.svg';
import CopyWrapper from '../CopyWrapper';
import { identity } from '../../utils/getIdentity';
import { useLocation, useParams } from "react-router-dom";
import { adminApi } from "../../service/admin-api";
import { notificationsErrorShow } from "../../redux/actions/notifications";
import { useDispatch } from "react-redux";
import AdminDataModal from "./AdminDataModal";
import { cryptoApi } from "../../service/cryptopay-api";

const WALLET_DATA_LABELS = {
  key: 'privateKey',
  mnemonic: 'mnemonicPhrase',
  passphrase: 'passphrase'
};

const BTC_CODE = '5edf2767c9ca4d5a342bf8ac';

const Wallet = ({
  wallet,
  typeWallet,
  page,
  pages,
  countItem,
  walletsFilters,
  openModal,
  openNotification,
  deleteMerchantWallet,
  merchantId,
  handleReplenishButton,
  setWalletAsMain,
  currencies,
  networkFilter,
  getWalletPrivateKey,
  getWalletMnemonicPhrase,
  getPassphrase,
  setShowWalletBy,
  isFromMerchantsWallets,
  updateIsSegwit
}) => {
  const [isWalletOpened, setIsWalletOpened] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const walletAddressRef = useRef(null);
  const [tooltipVisibility, setTooltipVisibility] = useState({});
  const [openPassphraseModal, setOpenPassphraseModal] = useState(false);
  const location = window.location.href;
  const locations = useLocation();
  const { userId } = useParams();
  const [adminData, setAdminData] = useState('');
  const [adminDataTitle, setAdminDataTitle] = useState('');
  const [isOpenAdminModal, setIsOpenAdminModal] = useState(false);

  const dispatch = useDispatch();

  const isAdmin = location?.includes('admin');

  useEffect(() => {
    const element = walletAddressRef.current;

    const checkOverflow = () => {
      if (element) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      }
    };

    checkOverflow();

    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [wallet.address]);

  const getMinReplenishValue = () => {
    const walletCurrency = currencies.find(currency => currency._id === wallet.currencyId);

    if (wallet.typeWallet === 'merchant') {
      return `0.${new Array(walletCurrency.decimals).join(0)}1`;
    } else {
      return walletCurrency.replenishMinimumValue;
    }
  };

  const getUserData = (type) => {
    if (type === 'private-key') {
      getWalletPrivateKey(merchantId, wallet._id);
      setShowWalletBy(WALLET_DATA_LABELS.key);
    } else if (type === 'passphrase') {
      getPassphrase(merchantId, wallet._id);
      setShowWalletBy(WALLET_DATA_LABELS.passphrase);
    } else if (type === 'mnemonic') {
      getWalletMnemonicPhrase(merchantId, wallet._id);
      setShowWalletBy(WALLET_DATA_LABELS.mnemonic);
    }
    openModal(wallet._id);
  }

  const changeToSegwit = async () => {
    try {
      const response = await cryptoApi.changeToSegwit(merchantId, wallet?._id);
      updateIsSegwit()
    } catch(error) {
      console.error(error)
    }
  }

  const getAdminData = async (type) => {
    try {
      const response = await adminApi.getAdminWalletData(type, userId, merchantId, wallet._id);
      if (response && response?.data) {
        setAdminData(response?.data);

        if (type === 'private-key') {
          setAdminDataTitle('Private key');
        } else if (type === 'passphrase') {
          setAdminDataTitle('Passphrase');
        } else {
          setAdminDataTitle('Mnemonic phrase');
        }

        setIsOpenAdminModal(true)
      }
    } catch (error) {
      setAdminData('');
      setAdminDataTitle('');
      dispatch(notificationsErrorShow({ message: error?.data?.message || '' }));
    }
  };

  const closeAdminModal = () => {
    setIsOpenAdminModal(false);
    setAdminData('');
    setAdminDataTitle('');
  }

  return (
    <div className={`wallet ${wallet.typeWallet === 'merchant' && !wallet.main ? 'wallet__secondary' : ''}`}>
      <Row align="middle">
        <Col span={currentWidth >= 1024 ? 1 : 1}>
          {wallet.tokens && wallet.tokens.length && wallet.typeWallet !== 'user' ? (
            <div
              className={cn(`wallet__expand`, {
                wallet__expand_opened: isWalletOpened,
              })}
            >
              <ArrowDownIcon onClick={() => setIsWalletOpened(prevValue => !prevValue)} />
            </div>
          ) : null}
        </Col>
        <Col sm={{ span: currentWidth >= 1024 ? 1 : 2, offset: 0 }} xs={{ span: 3, offset: 1 }}>
          <div className="wallet__icon">
            <TokenIcon tokenName={wallet.currency} />
          </div>
        </Col>

        <Col sm={{ span: currentWidth >= 1024 ? 7 : 8 }} xs={{ span: 17 }}>
          <div className="wallet__tooltip">
            <CopyWrapper type="wallet" copyContent={wallet.address}>
              <div className={`wallet__address wallet__address-${identity}`} ref={walletAddressRef}>
                <span>{wallet?.address}</span>
              </div>
            </CopyWrapper>
            {isOverflowing && (
              <Tooltip
                title={wallet.address}
                visible={tooltipVisibility[wallet._id]}
                onVisibleChange={isVisible => setTooltipVisibility({ ...tooltipVisibility, [wallet._id]: isVisible })}
              >
                <span
                  className="wallet__mark"
                  onMouseEnter={() => setTooltipVisibility({ ...tooltipVisibility, [wallet._id]: true })}
                  onMouseLeave={() => setTooltipVisibility({ ...tooltipVisibility, [wallet._id]: false })}
                >
                  *
                </span>
              </Tooltip>
            )}
          </div>
        </Col>
        <Col sm={{ span: currentWidth >= 1024 ? 4 : 7, offset: 1 }} xs={{ span: 11, offset: 5 }}>
          <div className="wallet__currency-amount">
            <span>
              {wallet.balance.value} {wallet.currency}{' '}
              {wallet.currencyType === 'token' ? tokenNodeTypeObj[wallet.nodeType] : null}
              {tokenTypeEnd(wallet.currencyType, wallet.nodeType, wallet.currency)}
            </span>
          </div>
        </Col>
        <Col sm={{ span: currentWidth >= 1024 ? 3 : 5 }} xs={{ span: 8 }}>
          <div className="wallet__usd-amount">
            <span>{formatCurrency(wallet.balance.usd || 0)}</span>
          </div>
        </Col>
        <Col
          sm={{ span: currentWidth >= 1024 ? 3 : 13, offset: currentWidth >= 1024 ? 0 : 3 }}
          xs={{ span: 10, offset: 5 }}
        >
          {t(moment(wallet.createdAt).format('MMM').toLowerCase())} {moment(wallet.createdAt).format('D, YYYY, HH:mm')}
        </Col>
        <Col
          sm={{ span: currentWidth >= 1024 ? 2 : 3, offset: currentWidth >= 1024 ? 1 : 3 }}
          xs={{ span: 6, offset: 1 }}
        >
          <div
            className={`wallet__status ${
              wallet.typeWallet === 'merchant' && !wallet.main ? 'wallet__status_secondary' : 'wallet__status_default'
            }`}
          >
            {wallet.typeWallet === 'merchant' && !wallet.main ? (
              <span>{t('secondary')}</span>
            ) : (
              <span>{t('default')}</span>
            )}
          </div>
        </Col>
        <Col span={currentWidth >= 1024 ? 1 : 2}>
          <Dropdown
            overlay={
              <Menu>
                {wallet.typeWallet === 'merchant' && !wallet.main && (
                  <Menu.Item
                    key="0"
                    onClick={() =>
                      setWalletAsMain(
                        merchantId,
                        wallet._id,
                        wallet.currencyId,
                        typeWallet,
                        page,
                        walletsFilters,
                        networkFilter
                      )
                    }
                  >
                    <div>{t('wallets.setAsDefault')}</div>
                  </Menu.Item>
                )}
                {!isAdmin && (
                  <Menu.Item key="1" onClick={() => handleReplenishButton(wallet.address, wallet.currency, true)}>
                    <div>{t('merchants.replenish')}</div>
                  </Menu.Item>
                )}
                <Menu.Item key="2">
                  <div
                    onClick={() => {
                      if (!isAdmin) {
                        getUserData('private-key')
                      } else {
                        getAdminData('private-key')
                      }
                    }}
                  >
                    {t('showPrivateKey')}
                  </div>
                </Menu.Item>
                <Menu.Item key="5">
                  <div
                    onClick={() => {
                      if (!isAdmin) {
                        getUserData('passphrase')
                      } else {
                        getAdminData('passphrase')
                      }
                    }}
                  >
                    {t('showPassphrase')}
                  </div>
                </Menu.Item>
                {wallet.currencyId !== BTC_CODE ? (
                  <Menu.Item key="4">
                    <div
                      onClick={() => {
                        if (!isAdmin) {
                          getUserData('mnemonic')
                        } else {
                          getAdminData('mnemonic')
                        }
                      }}
                    >
                      {t('showMnemonic')}
                    </div>
                  </Menu.Item>
                ) : null}
                {!isAdmin && (
                  <Menu.Item key="3">
                    <WalletDeleteModal
                      merchantId={merchantId}
                      walletId={wallet._id}
                      walletAddress={wallet.address}
                      deleteMerchantWallet={deleteMerchantWallet}
                      typeWallet={typeWallet}
                      page={page}
                      pages={pages}
                      countItem={countItem}
                      walletsFilters={walletsFilters}
                      isFromMerchantsWallets={isFromMerchantsWallets}
                    />
                  </Menu.Item>
                )}
                {wallet?.nodeType === 'btc' && !wallet?.isSegwit && (
                  <Menu.Item onClick={changeToSegwit}>
                    Change to Native SegWit
                  </Menu.Item>
                )}
              </Menu>
            }
            trigger={['click']}
          >
            <div className="wallet__options">
              <img src={moreIcon} alt="options" />
            </div>
          </Dropdown>
        </Col>
      </Row>

      <div
        className={cn('wallet-tokens', {
          'wallet-tokens_opened': isWalletOpened,
        })}
      >
        {wallet.tokens && wallet.tokens.length && wallet.typeWallet !== 'user' ? (
          wallet.tokens.map(token => (
            <Row align="middle" className="wallet-tokens__item" key={crypto.randomUUID()}>
              <Col sm={{ span: currentWidth >= 1024 ? 1 : 2, offset: 1 }} xs={{ span: 3, offset: 2 }}>
                <TokenIcon className="wallet-tokens__icon" tokenName={token.currency} />
              </Col>
              <Col sm={{ span: currentWidth >= 1024 ? 7 : 8 }} xs={{ span: 17 }}>
                <div className="wallet__tooltip">
                  <CopyWrapper type="wallet" copyContent={wallet.address}>
                    <div className="wallet-tokens__address">{wallet.address}</div>
                  </CopyWrapper>
                  {isOverflowing && (
                    <Tooltip
                      title={wallet.address}
                      visible={tooltipVisibility[token.currencyId]}
                      onVisibleChange={isVisible =>
                        setTooltipVisibility({ ...tooltipVisibility, [token.currencyId]: isVisible })
                      }
                    >
                      <span
                        className="wallet__mark"
                        onMouseEnter={() => setTooltipVisibility({ ...tooltipVisibility, [token.currencyId]: true })}
                        onMouseLeave={() => setTooltipVisibility({ ...tooltipVisibility, [token.currencyId]: false })}
                      >
                        *
                      </span>
                    </Tooltip>
                  )}
                </div>
              </Col>
              <Col sm={{ span: currentWidth >= 1024 ? 4 : 7, offset: 1 }} xs={{ span: 11, offset: 5 }}>
                <div>
                  {token.balance.value} {token.currency}
                </div>
              </Col>
              <Col sm={{ span: currentWidth >= 1024 ? 3 : 5 }} xs={{ span: 8 }}>
                <div>{formatCurrency(token.balance.usd || 0)}</div>
              </Col>
            </Row>
          ))
        ) : (
          <></>
        )}
      </div>
      <AdminDataModal
        title={adminDataTitle}
        data={adminData}
        visible={isOpenAdminModal}
        close={closeAdminModal}
      />
      {/*<div className="wallet__min-replenish">Minimum deposit amount for wallet is {getMinReplenishValue()}</div>*/}
    </div>
  );
};

export default Wallet;
