import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import cn from 'classnames';
import TokenIcon from '../TokenIcon';
import formatCurrency from '../../utils/currencyFormatter';
import useWindowSize from '../../utils/resizeHook';
import { tokenNodeTypeObj, tokenTypeEnd } from '../../utils/tokenNodeTypes';
import './Wallet.scss';
import { ReactComponent as ArrowDownIcon } from '../../img/swap-svg/swap-select-arrow.svg';
import settingsIcon from '../../img/default-svg/settings.svg';
import trashIcon from '../../img/default-svg/trash.svg';
import lockedIcon from '../../img/default-svg/lock-slash.svg';
import unlockedIcon from '../../img/default-svg/lockIcon.svg';
import autosign from '../../img/default-svg/autosign.svg';
import autosignOff from '../../img/default-svg/autosign-off.svg';

import CopyWrapper from '../CopyWrapper';
import { identity } from '../../utils/getIdentity';
import WalletDeleteModal from './MerchantWalletsActions/WalletDeleteModal';
import WalletSettingsModal from './MerchantWalletsActions/WalletSettingsModal';
import SignPasswordModal from '../SignPasswordModal/SignPasswordModal';
import { setPasswordSignature, disableNewAutosign } from '../../redux/actions/withdraw';
import { cryptoApi } from '../../service/cryptopay-api';
import { disableNewAutosignSuccess, downloadNewAutosignError, resetAutosignStep, resetNewAutosign } from '../../redux/actions/withdraw';
import { useDispatch } from 'react-redux';
import PasswordModal from "./PasswordModal";

const WalletItem = ({
  wallet,
  typeWallet,
  page,
  pages,
  type,
  countItem,
  walletsFilters,
  openModal,
  openNotification,
  deleteMerchantWallet,
  merchantId,
  handleReplenishButton,
  setPasswordSignature,
  setWalletAsMain,
  currencies,
  networkFilter,
  autosignStatus,
  passwordStatus,
  getWalletPrivateKey,
  getWalletMnemonicPhrase,
  getPassphrase,
  setShowWalletBy,
  isFromMerchantsWallets,
  updateList,
  resetTwoFaState,
  checkAutosignStatus,
  downloadAutosign,
  checkPasswordStatus,
  autosignStep,
  downloadAutosignStep,
  downloadError,
  setIsSegwit
}) => {
  const [isWalletOpened, setIsWalletOpened] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isOpenSignPassword, setIsOpenSignPassword] = useState(false);
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const walletAddressRef = useRef(null);
  const [tooltipVisibility, setTooltipVisibility] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [statusAutosign, setStatusAutosign] = useState(true);
  const [statusPassword, setStatusPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [isOpenDownload, setIsOpenDownload] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const location = window.location.href;

  const isAdmin = location?.includes('admin');

  const dispatch = useDispatch();

  const closeSign = () => {
    setIsOpenSignPassword(false);
    dispatch(downloadNewAutosignError(false))
    setModalTitle(false);
    checkStatusPassword();
  }

  const openDelete = () => setOpenDeleteModal(true);
  const openSettings = wallet => {
    setSelectedWallet(wallet);
    setOpenSettingsModal(true);
  };

  const openDownload = () => {
    setOpenSettingsModal(false);
    setIsOpenDownload(true)
  }

  useEffect(() => {
    const element = walletAddressRef.current;

    const checkOverflow = () => {
      if (element) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      }
    };

    checkOverflow();

    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [wallet.address]);

  const getMinReplenishValue = () => {
    const walletCurrency = currencies.find(currency => currency._id === wallet.currencyId);

    if (wallet.typeWallet === 'merchant') {
      return `0.${new Array(walletCurrency.decimals).join(0)}1`;
    } else {
      return walletCurrency.replenishMinimumValue;
    }
  };

  const openSignModal = () => {
    if (statusAutosign) {
      setIsOpenSignPassword(true)
    } else {
      return
    }
  }

  const checkDownloadAutosign = () => {
    checkStatus();
    checkStatusPassword();
  }

  const checkStatus = async () => {
    const response = await cryptoApi.checkAutosignStatus(merchantId, wallet?._id);
    const data = await response.data;

    setStatusAutosign(data);
  };

  const checkStatusPassword = async () => {
    try {
      const response = await cryptoApi.checkPasswordStatus(merchantId, wallet?._id);
      const data = await response.data;
      setStatusPassword(data);
    } catch (e) {
      console.error(e);
      setStatusPassword(false);
    }
  };

  useEffect(() => {
    if (!isAdmin) {
      checkStatus();
      checkStatusPassword();
    }
  }, []);

  return (
    <>
      <div className={`wallet ${wallet.typeWallet === 'merchant' && !wallet.main ? 'wallet__secondary' : ''}`}>
        <Row align="middle">
          <Col span={1}>
            {wallet.tokens && wallet.tokens.length && wallet.typeWallet !== 'user' ? (
              <div
                className={cn(`wallet__expand`, {
                  wallet__expand_opened: isWalletOpened,
                })}
              >
                <ArrowDownIcon onClick={() => setIsWalletOpened(prevValue => !prevValue)} />
              </div>
            ) : null}
          </Col>
          <Col sm={{ span: currentWidth >= 1024 ? 1 : 2, offset: 0 }} xs={{ span: 3, offset: 1 }}>
            <div className="wallet__icon">
              <TokenIcon tokenName={wallet.currency} />
            </div>
          </Col>

          <Col sm={{ span: currentWidth >= 1024 ? 5 : 8, offset: 0 }} xs={{ span: 17 }}>
            <div className="wallet__tooltip">
              <CopyWrapper type="wallet" copyContent={wallet.address}>
                <div className={`wallet__address wallet__address-${identity}`} ref={walletAddressRef}>
                  <span>{wallet?.address}</span>
                </div>
              </CopyWrapper>
              {isOverflowing && (
                <Tooltip
                  title={wallet.address}
                  visible={tooltipVisibility[wallet._id]}
                  onVisibleChange={isVisible => setTooltipVisibility({ ...tooltipVisibility, [wallet._id]: isVisible })}
                >
                  <span
                    className="wallet__mark"
                    onMouseEnter={() => setTooltipVisibility({ ...tooltipVisibility, [wallet._id]: true })}
                    onMouseLeave={() => setTooltipVisibility({ ...tooltipVisibility, [wallet._id]: false })}
                  >
                    *
                  </span>
                </Tooltip>
              )}
            </div>
          </Col>
          <Col sm={{ span: currentWidth >= 1024 ? 4 : 7, offset: 1 }} xs={{ span: 11, offset: 5 }}>
            <div className="wallet__currency-amount">
              <span>
                {wallet.balance.value} {wallet.currency}{' '}
                {wallet.currencyType === 'token' ? tokenNodeTypeObj[wallet.nodeType] : null}
                {tokenTypeEnd(wallet.currencyType, wallet.nodeType, wallet.currency)}
              </span>
            </div>
          </Col>
          <Col sm={{ span: type === 'admin' ? 5 : currentWidth >= 1024 ? 2 : 5, offset: 0 }} xs={{ span: 8 }}>
            <div className="wallet__usd-amount">
              <span>{formatCurrency(wallet.balance.usd || 0)}</span>
            </div>
          </Col>
          <Col
            sm={{ span: type === 'admin' ? 5 : currentWidth >= 1024 ? 3 : 10, offset: currentWidth >= 1024 ? 0 : 1 }}
            xs={{ span: 10, offset: 5 }}
          >
            {t(moment(wallet.createdAt).format('MMM').toLowerCase())}{' '}
            {moment(wallet.createdAt).format('D, YYYY, HH:mm')}
          </Col>
          {wallet.typeWallet === 'merchant' && type !== 'admin' && (
            <Col
              sm={{ span: currentWidth >= 1024 ? 1 : 1, offset: currentWidth >= 1024 ? 1 : 1 }}
              xs={{ span: 10, offset: 5 }}
              style={currentWidth >= 1024 && { marginLeft: '3.5%' }}
            >
              {' '}
              <img
                src={statusAutosign ? autosignOff : autosign}
                alt="lock"
              />
            </Col>
          )}
          {wallet.typeWallet === 'merchant' && type !== 'admin' && (
            <Col
              sm={{ span: currentWidth >= 1024 ? 1 : 1, offset: currentWidth >= 1024 ? 1 : 2 , marginLeft: '4% !important'}}
              xs={{ span: 1, offset: 5 }}
              style={{ marginLeft: '4%' }}
            >
              {' '}
              <img
                className="wallet__icon"
                onClick={openSignModal}
                src={!statusPassword ? lockedIcon : unlockedIcon}
                alt="lock"
              />
            </Col>
          )}
          <Col
            sm={{ span: currentWidth >= 1024 ? 2 : 2, offset: currentWidth >= 1024 ? 0 : 3 }}
            xs={{ span: 10, offset: 5 }}
          >
            <div
              className={`wallet__status ${
                wallet.typeWallet === 'merchant' && !wallet.main ? 'wallet__status_secondary' : 'wallet__status_default'
              }`}
            >
              {wallet.typeWallet === 'merchant' && !wallet.main ? (
                <span>{t('secondary')}</span>
              ) : (
                <span>{t('default')}</span>
              )}
            </div>
          </Col>
          {type !== 'admin' && (
            <Col
              sm={{ span: currentWidth >= 1024 ? 1 : 1, offset: currentWidth >= 1024 ? 0 : 1 }}
              xs={{ span: 1, offset: 1 }}
            >
              <div style={{ display: 'flex', gap: '6px' }}>
                <img
                  style={{ cursor: 'pointer' }}
                  src={settingsIcon}
                  alt="settings"
                  onClick={() => openSettings(wallet)}
                />
                <img style={{ cursor: 'pointer' }} src={trashIcon} alt="remove" onClick={openDelete} />
              </div>
            </Col>
          )}
        </Row>

        <div
          className={cn('wallet-tokens', {
            'wallet-tokens_opened': isWalletOpened,
          })}
        >
          {wallet.tokens && wallet.tokens.length && wallet.typeWallet !== 'user' ? (
            wallet.tokens.map(token => (
              <Row align="middle" className="wallet-tokens__item" key={crypto.randomUUID()}>
                <Col sm={{ span: currentWidth >= 1024 ? 1 : 2, offset: 1 }} xs={{ span: 3, offset: 2 }}>
                  <TokenIcon className="wallet-tokens__icon" tokenName={token.currency} />
                </Col>
                <Col sm={{ span: currentWidth >= 1024 ? 5 : 8 }} xs={{ span: 17 }}>
                  <div className="wallet__tooltip">
                    <CopyWrapper type="wallet" copyContent={wallet.address}>
                      <div className="wallet-tokens__address">{wallet.address}</div>
                    </CopyWrapper>
                    {isOverflowing && (
                      <Tooltip
                        title={wallet.address}
                        visible={tooltipVisibility[token.currencyId]}
                        onVisibleChange={isVisible =>
                          setTooltipVisibility({ ...tooltipVisibility, [token.currencyId]: isVisible })
                        }
                      >
                        <span
                          className="wallet__mark"
                          onMouseEnter={() => setTooltipVisibility({ ...tooltipVisibility, [token.currencyId]: true })}
                          onMouseLeave={() => setTooltipVisibility({ ...tooltipVisibility, [token.currencyId]: false })}
                        >
                          *
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </Col>
                <Col sm={{ span: currentWidth >= 1024 ? 4 : 7, offset: 1 }} xs={{ span: 11, offset: 5 }}>
                  <div>
                    {token.balance.value} {token.currency}
                  </div>
                </Col>
                <Col sm={{ span: currentWidth >= 1024 ? 3 : 5 }} xs={{ span: 8 }}>
                  <div>{formatCurrency(token.balance.usd || 0)}</div>
                </Col>
              </Row>
            ))
          ) : (
            <></>
          )}
        </div>
        {/*<div className="wallet__min-replenish">Minimum deposit amount for wallet is {getMinReplenishValue()}</div>*/}
      </div>

      {openDeleteModal && (
        <WalletDeleteModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          merchantId={merchantId}
          walletId={wallet._id}
          walletAddress={wallet.address}
          deleteMerchantWallet={deleteMerchantWallet}
          typeWallet={typeWallet}
          page={page}
          pages={pages}
          countItem={countItem}
          walletsFilters={walletsFilters}
          isFromMerchantsWallets={isFromMerchantsWallets}
        />
      )}

      <SignPasswordModal
        merchantId={merchantId}
        autosignStatus={autosignStatus}
        passwordStatus={statusPassword}
        isOpen={isOpenSignPassword}
        walletId={wallet?._id}
        downloadAutosign={downloadAutosign}
        setPasswordSignature={setPasswordSignature}
        onCancel={closeSign}
        modalTitle={modalTitle}
        autosignStep={autosignStep}
        downloadAutosignStep={downloadAutosignStep}
        password={password}
      />

      {openSettingsModal && (
        <WalletSettingsModal
          openDownload={openDownload}
          updateList={checkDownloadAutosign}
          checkStatusPassword={checkStatusPassword}
          openSettingsModal={openSettingsModal}
          setOpenSettingsModal={setOpenSettingsModal}
          closeModal={() => setOpenSettingsModal(false)}
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          getWalletPrivateKey={getWalletPrivateKey}
          getPassphrase={getPassphrase}
          merchantId={merchantId}
          openNotification={openNotification}
          getWalletMnemonicPhrase={getWalletMnemonicPhrase}
          resetTwoFaState={resetTwoFaState}
          setWalletAsMain={setWalletAsMain}
          statusAutosign={statusAutosign}
          statusPassword={statusPassword}
          openSign={() => setIsOpenSignPassword(true)}
          typeWallet={typeWallet}
          walletsFilters={walletsFilters}
          page={page}
          networkFilter={networkFilter}
          downloadError={downloadError}
          setIsSegwit={setIsSegwit}
        />
      )}

      <PasswordModal
        isOpen={isOpenDownload}
        closeModal={() => setIsOpenDownload(false)}
        downloadAutosignStep={downloadAutosignStep}
        merchantId={merchantId}
        walletId={wallet?._id}
        downloadAutosign={downloadAutosign}
      />
    </>
  );
};

export default WalletItem;
