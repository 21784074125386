import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import SwapAmountField from '../SwapAmountField';
import { notificationsErrorShow } from '../../../redux/actions/notifications';
import { ReactComponent as SwapReverse } from '../../../img/swap-svg/swap-reverse.svg';
import { ReactComponent as SwapReverseFinvaro } from '../../../img/finvaro/swap-reverse.svg';
import { ReactComponent as SwapReverseClarnium } from '../../../img/clarnium/swap-reverse.svg';
import { getMerchantBalances } from '../../../redux/actions/merchantData';
import './style.scss';
import { identity } from "../../../utils/getIdentity";
import { getAdminFeeWalletsBalance } from "../../../redux/actions/adminPanel/adminFeeWallets";
import { getWithdrawalAdminEstimateMax } from "../../../redux/actions/adminPanel/adminFeeWithdraw";

const SwapExchange = ({
  exchangeFields,
  setExchangeFields,
  currencies,
  merchantId,
  networkFilter,
  merchantBalances,
  balancesFetching,
  getMerchantBalances,
  exchangeWalletsFetching,
  walletFromId,
  exchangeWalletsFrom,
  swapOffers,
  priceForSwap,
  swapOffersFetching,
  swapOffersReset,
  notificationsErrorShow,
  swapOffersUpdating,
  polkaDotLimitWarning,
  setPolkaDotLimitWarning,
  setDataIsFrozen,
  setCurrencyFieldIsEdited,
  isSwapStable,
  swapReceiveReset,
  password,
  sign,
  getAdminBalances,
  getWithdrawalAdminEstimateMax,
  swingLoading
}) => {
  const [maxAmountFetching, setMaxAmountFetching] = useState(false);
  const [balances, setBalances] = useState([]);
  const { t } = useTranslation();
  const location = window.location.href;
  const dispatch = useDispatch();

  const isAdmin = location?.includes('admin');
  const isSwapSwing = location?.includes('swing');

  useEffect(() => {
    if (merchantBalances?.length && isSwapSwing) {
      const newBalances = merchantBalances?.filter(item => !['btc', 'trx', 'dot', 'ksm', 'solana', 'ton'].includes(item?.currency?.nodeType));

      setBalances(newBalances);
    }
  }, [merchantBalances]);

  const currentBalances = isAdmin ? getAdminBalances : isSwapSwing ? balances : merchantBalances;

  useEffect(() => {
    if (merchantId && networkFilter && !isAdmin) {
      getMerchantBalances(merchantId, networkFilter, true);
    }
  }, [merchantId, networkFilter]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getAdminFeeWalletsBalance({ typeNetwork: networkFilter }));
    }
  }, [isAdmin, networkFilter])

  const swapExchangeFields = () => {
    const newState = [...exchangeFields].reverse().map((item, index) => ({ ...item, index }));
    setExchangeFields(newState);
  };

  const handleInputChange = (newValue, fieldIndex) => {
    const newState = [...exchangeFields];
    newState[fieldIndex].value = newValue;
    setExchangeFields(newState);
  };

  useEffect(() => {
    if (isSwapStable) {
      handleInputChange(priceForSwap ? priceForSwap?.amountToBeReceived : 0, 1);
    }
  }, [priceForSwap]);

  const handleCurrencyChange = (newSelectedCurrency, fieldIndex) => {
    setCurrencyFieldIsEdited(fieldIndex);

    if (fieldIndex === 0) {
      const decimalLimit = currencies.find(currency => currency._id === newSelectedCurrency).decimals;

      const oldInputValue = exchangeFields[fieldIndex].value;
      const isdecimal = oldInputValue && oldInputValue.indexOf('.') >= 0 ? '.' : '';

      if (oldInputValue && isdecimal) {
        const oldInputValueSplit = isdecimal && oldInputValue.split('.');
        oldInputValueSplit[1] = oldInputValueSplit[1].substr('0', decimalLimit);
        const newValue = oldInputValueSplit ? oldInputValueSplit.join('.') : oldInputValue;
        handleInputChange(newValue, fieldIndex);
      }
    }

    const oppositeFieldIndex = +!fieldIndex;
    const newState = [...exchangeFields];

    if (newState[oppositeFieldIndex].selectedCurrency === newSelectedCurrency) {
      newState[oppositeFieldIndex].selectedCurrency = exchangeFields[fieldIndex].selectedCurrency;
      setCurrencyFieldIsEdited('both');
    }

    newState[fieldIndex].selectedCurrency = newSelectedCurrency;

    setExchangeFields(newState);
  };

  return (
    <div className="swap-exchange">
      {exchangeFields.map((exchangeField, index) => (
        <React.Fragment key={index}>
          <SwapAmountField
            priceForSwap={priceForSwap}
            key={exchangeField?.index}
            merchantId={merchantId}
            title={index ? t('swap.exchange.get') : t('swap.exchange.send')}
            currencies={currencies}
            exchangeField={exchangeField}
            handleInputChange={handleInputChange}
            handleCurrencyChange={handleCurrencyChange}
            merchantBalances={currentBalances}
            merchantBalance={currentBalances.find(item => item.currency.id === exchangeFields[index].selectedCurrency)}
            disabled={index}
            walletFromId={walletFromId}
            exchangeWalletsFrom={exchangeWalletsFrom}
            withMax={!index}
            swapOffers={swapOffers}
            swapOffersFetching={swapOffersFetching}
            swapOffersReset={swapOffersReset}
            swapReceiveReset={swapReceiveReset}
            setMaxAmountFetching={setMaxAmountFetching}
            notificationsErrorShow={notificationsErrorShow}
            swapOffersUpdating={swapOffersUpdating}
            polkaDotLimitWarning={polkaDotLimitWarning}
            setPolkaDotLimitWarning={setPolkaDotLimitWarning}
            youSendFiledValue={exchangeFields[0].value}
            setDataIsFrozen={setDataIsFrozen}
            isSwapStable={isSwapStable}
            password={password}
            sign={sign}
            getWithdrawalAdminEstimateMax={getWithdrawalAdminEstimateMax}
          />
          {!index &&
            (!exchangeWalletsFetching && !maxAmountFetching && !swingLoading ? (
              <div
                className="swap-exchange__reverse"
                onClick={() => {
                  swapExchangeFields();
                  setCurrencyFieldIsEdited('both');
                }}
                style={{ marginTop: `${!isSwapStable ? '-11px' : '-32px'}` }}
              >
                {identity === 'finvaro' ? <SwapReverseFinvaro /> : identity === 'clarnium' ? <SwapReverseClarnium /> : <SwapReverse />}
              </div>
            ) : (
              <div className="swap-exchange__reverse swap-exchange__reverse_fetching">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </div>
            ))}
        </React.Fragment>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  merchantBalances: state.merchantData.balances.balances || [],
  balancesFetching: state.merchantData.fetching,
  exchangeWalletsFetching: state.swap.offers.fetching,
  getAdminBalances: state.adminFeeWallets.adminWalletsBalance.data,
});

const mapDispatchToProps = dispatch => ({
  getMerchantBalances: getMerchantBalances(dispatch),
  getWithdrawalAdminEstimateMax: getWithdrawalAdminEstimateMax(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwapExchange);
