import React from 'react';
import { Modal } from "antd";
import { ReactComponent as CloseSVG } from "../../../../img/default-svg/close-black.svg";
import '../../../Webhooks/WebhooksWrapper/style.scss';
import '../../../../layout/Modal.scss';
import Button from "../../../Button";

const MultisendFeeModal = ({ open, close, submit }) => {
  const handleSubmit = () => {
    submit();
    close();
  }

  return (
    <Modal
      width={450}
      title="Multisend"
      visible={open}
      onCancel={close}
      footer={null}
      centered
      closeIcon={<CloseSVG />}
      destroyOnClose
      maskClosable={false}
    >
      <div className="webhook-modal">
        To estimate the Miner Fee, an external call will be required, which involves paying a blockchain fee.

        <div className="webhook-modal__fee">
          <Button type="secondary" onClick={close}>Get Back</Button>
          <Button type="primary" onClick={handleSubmit}>Continue</Button>
        </div>
      </div>
    </Modal>
)
};

export default MultisendFeeModal;