import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow } from './notifications';

export const SWAP_HISTORY_GET = 'SWAP_HISTORY_GET';
export const SWAP_HISTORY_SET = 'SWAP_HISTORY_SET';
export const SWAP_HISTORY_ERROR = 'SWAP_HISTORY_ERROR';

export const swapHistoryGet = page => ({
  type: SWAP_HISTORY_GET,
  page,
});

export const swapHistorySet = data => ({
  type: SWAP_HISTORY_SET,
  payload: data,
});

export const swapHistoryError = () => ({
  type: SWAP_HISTORY_ERROR,
});

export const getSwapHistory =
  dispatch =>
  async (merchantId, body, fromSockets = false) => {
    !fromSockets && dispatch(swapHistoryGet(body.page));

    try {
      const response = await cryptoApi.getSwapHistory(merchantId, body);

      if (response && response.data) {
        dispatch(swapHistorySet(response.data));
      }
    } catch (error) {
      dispatch(swapHistoryError());
      dispatch(notificationsErrorShow(error.data));
    }
  };

export const SWAP_EXCHANGE_WALLETS_GET = 'SWAP_EXCHANGE_WALLETS_GET';
export const SWAP_EXCHANGE_WALLETS_SET = 'SWAP_EXCHANGE_WALLETS_SET';
export const SWAP_EXCHANGE_WALLETS_RESET = 'SWAP_EXCHANGE_WALLETS_RESET';
export const SWAP_EXCHANGE_WALLETS_ERROR = 'SWAP_EXCHANGE_WALLETS_ERROR';

export const swapExchangeWalletsGet = () => ({
  type: SWAP_EXCHANGE_WALLETS_GET,
});

export const swapExchangeWalletsSet = data => ({
  type: SWAP_EXCHANGE_WALLETS_SET,
  payload: data,
});

export const swapExchangeWalletsReset = () => ({
  type: SWAP_EXCHANGE_WALLETS_RESET,
});

export const swapExchangeWalletsError = () => ({
  type: SWAP_EXCHANGE_WALLETS_ERROR,
});

export const getSwapExchangeWallets = dispatch => async (merchantId, params) => {
  const { currencyIds } = params;

  dispatch(swapExchangeWalletsGet());

  try {
    const wallets = await Promise.all(
      currencyIds.map(currencyId => cryptoApi.getMerchantWallets(merchantId, { ...params, currencyIds: currencyId }))
    );

    if (wallets && wallets[0].data && wallets[1].data) {
      dispatch(swapExchangeWalletsSet([wallets[0].data, wallets[1].data]));
    }
  } catch (err) {
    // dispatch(swapExchangeWalletsError());
    console.log('err in getSwapExchangeWallet', err, err.data);
    const errorMessages = err?.data?.message ?? [];
    console.log('errorMessages', errorMessages);
    const notCurrencyIdsError = errorMessages?.filter(
      errMess => errMess !== 'each value in currencyIds must be a mongodb id'
    );
    console.log('notCurrencyIdsError', notCurrencyIdsError);
    if (notCurrencyIdsError.length > 0) {
      dispatch(swapExchangeWalletsError());
      dispatch(notificationsErrorShow(err.data));
    }
  }
};

export const SWAP_EXCHANGE_WALLET_GET = 'SWAP_EXCHANGE_WALLET_GET';
export const SWAP_EXCHANGE_WALLET_SET = 'SWAP_EXCHANGE_WALLET_SET';

export const swapExchangeWalletGet = () => ({
  type: SWAP_EXCHANGE_WALLET_GET,
});

export const swapExchangeWalletSet = data => ({
  type: SWAP_EXCHANGE_WALLET_SET,
  payload: data,
});

export const getSwapExchangeWallet = dispatch => async (merchantId, params, currencyFieldIsEdited) => {
  dispatch(swapExchangeWalletGet());

  try {
    const wallets = await cryptoApi.getMerchantWallets(merchantId, { ...params, currencyIds: params.currencyIds });

    if (wallets && wallets?.data?.entities) {
      dispatch(swapExchangeWalletSet({ data: wallets.data, number: currencyFieldIsEdited }));
    }
  } catch (err) {
    // dispatch(swapExchangeWalletsError());
    console.log('err in getSwapExchangeWallet', err, err.data);
    console.log('errrrrrrrr', err?.data?.data?.message ?? 'assaas');
    const errorMessages = err?.data?.data?.message ?? [];
    console.log('errorMessages', errorMessages);
    const notCurrencyIdsError = errorMessages?.filter(
      errMess => errMess !== 'each value in currencyIds must be a mongodb id'
    );
    console.log('notCurrencyIdsError', notCurrencyIdsError);
    if (notCurrencyIdsError) {
      dispatch(swapExchangeWalletsError());
      dispatch(notificationsErrorShow(err.data));
    }
  }
};

export const SWAP_OFFERS_GET = 'SWAP_OFFERS_GET';
export const SWAP_OFFERS_SET = 'SWAP_OFFERS_SET';
export const SWAP_OFFERS_RESET = 'SWAP_OFFERS_RESET';
export const SWAP_OFFERS_ERROR = 'SWAP_OFFERS_ERROR';

export const swapOffersGet = () => ({
  type: SWAP_OFFERS_GET,
});

export const swapOffersSet = data => ({
  type: SWAP_OFFERS_SET,
  payload: data,
});

export const swapOffersReset = () => ({
  type: SWAP_OFFERS_RESET,
});

export const swapOffersError = () => ({
  type: SWAP_OFFERS_ERROR,
});

export const getSwapOffers = dispatch => async body => {
  dispatch(resetSwapOffersUpdatedData());
  dispatch(swapOffersGet());

  try {
    const response = await cryptoApi.getSwapOffers(body);

    if (response && response.data) {
      dispatch(swapOffersSet(response.data));
    }
  } catch (error) {
    dispatch(swapOffersError());
    dispatch(notificationsErrorShow(error.data));
  }
};

export const SWAP_OFFERS_UPDATE_GET = 'SWAP_OFFERS_UPDATE_GET';
export const SWAP_OFFERS_UPDATE_SET = 'SWAP_OFFERS_UPDATE_SET';
export const SWAP_OFFERS_UPDATE_APPLY = 'SWAP_OFFERS_UPDATE_APPLY';
export const SWAP_OFFERS_UPDATE_ERROR = 'SWAP_OFFERS_UPDATE_ERROR';
export const RESET_SWAP_OFFERS_UPDATED_DATA = 'RESET_SWAP_OFFERS_UPDATED_DATA';

export const swapOffersUpdateGet = () => ({
  type: SWAP_OFFERS_UPDATE_GET,
});

export const swapOffersUpdateSet = data => ({
  type: SWAP_OFFERS_UPDATE_SET,
  payload: data,
});

export const swapOffersUpdateApply = () => ({
  type: SWAP_OFFERS_UPDATE_APPLY,
});

export const swapOffersUpdateError = () => ({
  type: SWAP_OFFERS_UPDATE_ERROR,
});

export const resetSwapOffersUpdatedData = () => ({
  type: RESET_SWAP_OFFERS_UPDATED_DATA,
});

export const updateSwapOffers = dispatch => async body => {
  dispatch(swapOffersUpdateGet());

  try {
    const response = await cryptoApi.getSwapOffers(body);

    if (response && response.data) {
      dispatch(swapOffersUpdateSet(response.data));
    }
  } catch (error) {
    dispatch(swapOffersUpdateError());
    dispatch(notificationsErrorShow(error.data));
  }
};

export const SWAP_EXCHANGE_GET = 'SWAP_EXCHANGE_GET';
export const SWAP_EXCHANGE_SET = 'SWAP_EXCHANGE_SET';
export const SWAP_EXCHANGE_ERROR = 'SWAP_EXCHANGE_ERROR';
export const RESET_SWAP_EXCHANGE_ERROR = 'RESET_SWAP_EXCHANGE_ERROR';

export const SWAP_SET_APP_TWO_FA = 'SWAP_SET_APP_TWO_FA';
export const SWAP_SET_EMAIL_TWO_FA = 'SWAP_SET_EMAIL_TWO_FA';
export const SWAP_SET_PHONE_TWO_FA = 'SWAP_SET_PHONE_TWO_FA';
export const SWAP_RESET_TWO_FA = 'SWAP_RESET_TWO_FA';

export const swapSetAppTwoFa = isAppTwoFa => ({
  type: SWAP_SET_APP_TWO_FA,
  isAppTwoFa,
});

export const swapSetEmailTwoFa = isEmailTwoFa => ({
  type: SWAP_SET_EMAIL_TWO_FA,
  isEmailTwoFa,
});

export const swapSetPhoneTwoFa = isPhoneTwoFa => ({
  type: SWAP_SET_PHONE_TWO_FA,
  isPhoneTwoFa,
});

export const swapResetTwoFa = () => ({
  type: SWAP_RESET_TWO_FA,
});

export const swapExchangeGet = () => ({
  type: SWAP_EXCHANGE_GET,
});

export const swapExchangeSet = data => ({
  type: SWAP_EXCHANGE_SET,
  payload: data,
});

export const swapExchangeError = data => ({
  type: SWAP_EXCHANGE_ERROR,
  payload: data,
});

export const resetExchangeError = () => ({
  type: RESET_SWAP_EXCHANGE_ERROR,
});

export const makeSwap = dispatch => async body => {
  dispatch(swapExchangeGet());
  let response;
  try {
    response = await cryptoApi.makeSwap(body);
  } catch (error) {
    console.log(error);
    dispatch(swapExchangeError(error.data.message));
    if (!error.data?.message.includes("You don't have enough")) {
      if (error.data?.message.includes('Internal partner error')) {
        dispatch(notificationsErrorShow({ message: 'Internal partner error, please select another partner' }));
      } else {
        dispatch(notificationsErrorShow(error?.data));
      }
    } else {
      dispatch(notificationsErrorShow(error?.data));
    }
  }
  if (response && response.data.twoFactorToken) {
    dispatch(swapSetAppTwoFa(true));
    return response;
  }

  if (response && response.data.emailSent) {
    dispatch(swapSetEmailTwoFa(true));
    return response;
  }

  if (response && response.data.codeSent) {
    dispatch(swapSetPhoneTwoFa(true));
    return response;
  }
  if (response && response.status === 'success') {
    dispatch(swapExchangeSet(response.data));
    return response;
  }
};

export const makeSwapSwing = dispatch => async body => {
  dispatch(swapExchangeGet());
  let response;
  try {
    response = await cryptoApi.makeSwapSwing(body);
    console.log(response)
  } catch (error) {
    console.log(error);
    dispatch(swapExchangeError(error.data.message));
    if (!error.data?.message.includes("You don't have enough")) {
      if (error.data?.message.includes('Internal partner error')) {
        dispatch(notificationsErrorShow({ message: 'Internal partner error, please select another partner' }));
      } else {
        dispatch(notificationsErrorShow(error?.data));
      }
    }
  }
  if (response && response.data.twoFactorToken) {
    console.log(3)
    dispatch(swapSetAppTwoFa(true));
    return response;
  }

  if (response && response.data.emailSent) {
    console.log(1)
    dispatch(swapSetEmailTwoFa(true));
    return response;
  }

  if (response && response.data.codeSent) {
    console.log(2)
    dispatch(swapSetPhoneTwoFa(true));
    return response;
  }
  if (response && response.status === 'success') {
    dispatch(swapExchangeSet(response.data));
    return response;
  }
};

export const AUTOSWAP_GET = 'AUTOSWAP_GET';
export const AUTOSWAP_POST = 'AUTOSWAP_POST';
export const AUTOSWAP_DELETE = 'AUTOSWAP_DELETE';

export const autoswapGet = data => ({
  type: AUTOSWAP_GET,
  payload: data,
});

export const getAutoswapSetting = dispatch => async merchantId => {
  try {
    const response = await cryptoApi.autoswapGet(merchantId);

    if (response && response.status === 'success') {
      dispatch(autoswapGet(response.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const postAutoswapSetting = dispatch => async (merchantId, data) => {
  try {
    await cryptoApi.autoswapPost(merchantId, data);
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
    return error;
  }
};

export const deleteAutoswapSetting = dispatch => async (merchantId, settingId) => {
  try {
    await cryptoApi.autoswapDelete(merchantId, settingId);
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};

export const patchAutoswapSetting = dispatch => async (merchantId, settingId, data) => {
  try {
    await cryptoApi.autoswapPatch(merchantId, settingId, data);
  } catch (error) {
    console.log(error);
    dispatch(notificationsErrorShow(error.data));
    return error;
  }
};
// getReceiveSwap
export const SWAP_RECEIVE_GET = 'SWAP_RECEIVE_GET';
export const SWAP_RECEIVE_SET = 'SWAP_RECEIVE_SET';
export const SWAP_RECEIVE_RESET = 'SWAP_RECEIVE_RESET';
export const SWAP_RECEIVE_ERROR = 'SWAP_RECEIVE_ERROR';

export const swapReceiveGet = () => ({
  type: SWAP_RECEIVE_GET,
});

export const swapReceiveSet = data => ({
  type: SWAP_RECEIVE_SET,
  payload: data,
});

export const swapReceiveReset = () => ({
  type: SWAP_RECEIVE_RESET,
});

export const swapReceiveError = () => ({
  type: SWAP_RECEIVE_ERROR,
});

export const getReceiveSwap = dispatch => async body => {
  // dispatch(resetSwapOffersUpdatedData());
  dispatch(swapReceiveGet());

  try {
    const response = await cryptoApi.getReceiveSwap(body);
    console.log('responsePrice', response);
    if (response && response.data) {
      dispatch(swapReceiveSet(response.data));
    }
  } catch (error) {
    console.log('ErrorresponsePrice', error);
    dispatch(swapReceiveError());
    dispatch(notificationsErrorShow(error.data));
  }
};

export const MAKE_STABLE_SWAP_REQUEST = 'MAKE_STABLE_SWAP_REQUEST';
export const MAKE_STABLE_SWAP_SUCCESS = 'MAKE_STABLE_SWAP_SUCCESS';
export const MAKE_STABLE_SWAP_FAIL = 'MAKE_STABLE_SWAP_FAIL';
export const RESET_STABLE_SWAP_ERROR = 'RESET_STABLE_SWAP_ERROR';

const makeStableSwapRequet = () => ({
  type: MAKE_STABLE_SWAP_REQUEST,
});

const makeStableSwapSuccess = data => ({
  type: MAKE_STABLE_SWAP_SUCCESS,
  payload: data,
});

const makeStableSwapFail = data => ({
  type: MAKE_STABLE_SWAP_FAIL,
  payload: data,
});

export const resetStableSwapError = () => ({
  type: RESET_STABLE_SWAP_ERROR,
});

export const makeStableSwap = dispatch => async body => {
  dispatch(makeStableSwapRequet());
  let response;
  try {
    response = await cryptoApi.makeStableSwap(body);
  } catch (error) {
    console.log(error);
    dispatch(makeStableSwapFail(error.data.message));
    if (!error.data?.message.includes("You don't have enough")) {
      if (error.data?.message.includes('Internal partner error')) {
        dispatch(notificationsErrorShow({ message: 'Internal partner error, please select another partner' }));
      } else {
        dispatch(notificationsErrorShow(error?.data));
      }
    }
  }
  if (response && response.data.twoFactorToken) {
    dispatch(swapSetAppTwoFa(true));
    return response;
  }

  if (response && response.data.emailSent) {
    dispatch(swapSetEmailTwoFa(true));
    return response;
  }

  if (response && response.data.codeSent) {
    dispatch(swapSetPhoneTwoFa(true));
    return response;
  }
  if (response && response.status === 'success') {
    dispatch(makeStableSwapSuccess(response.data));
    return response;
  }
};

// getSwapTokens
export const GET_SWAP_TOKENS_REQUEST = 'GET_SWAP_TOKENS_REQUEST';
export const GET_SWAP_TOKENS_SUCCESS = 'GET_SWAP_TOKENS_SUCCESS';
export const GET_SWAP_TOKENS_FAIL = 'GET_SWAP_TOKENS_FAIL';

const getSwapTokensRequest = () => ({
  type: GET_SWAP_TOKENS_REQUEST,
});

const getSwapTokensSuccess = payload => ({
  type: GET_SWAP_TOKENS_SUCCESS,
  payload,
});

const getSwapTokenstFail = payload => ({
  type: GET_SWAP_TOKENS_FAIL,
  payload,
});

export const getSwapTokens = dispatch => async () => {
  dispatch(getSwapTokensRequest());
  try {
    const response = await cryptoApi.getSwapTokens();

    dispatch(getSwapTokensSuccess(response.data));
  } catch (error) {
    dispatch(getSwapTokenstFail());
    dispatch(notificationsErrorShow(error?.data));
  }
};
