import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Form, Input, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import '../../layout/Modal.scss';
import './style.scss';
import '../ResendEmail2FA/style.scss';
import { useTranslation } from 'react-i18next';
import { Timer } from '../Timer/Timer';
import { addMinutes } from '../../utils/addMinutes';
import { identity } from '../../utils/getIdentity';
import { ReactComponent as SuccessIcon } from '../../img/default-svg/success.svg';
import { ReactComponent as DownloadIcon } from '../../img/cpay-svg/download.svg';
import Button from '../Button';
import { resetAutosignStep, resetDownloadAutosignStep } from "../../redux/actions/withdraw";

function SignPasswordModal({
  isOpen,
  onCancel,
  autosignStatus,
  setPasswordSignature,
  merchantId,
  walletId,
  downloadAutosign,
  passwordStatus,
  autosignStep,
  downloadAutosignStep,
  password,
  modalTitle
}) {
  const [step, setStep] = useState(0);
  const [emailTwoFa, setEmailTwoFa] = useState('');
  const [disableResend, setDisableResend] = useState(true);
  const [twoFactorToken, setTwoFactorToken] = useState('');
  const [twoVerifyCode, setTwoVerifyCode] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [values, setValues] = useState({
    oldPassword: '',
    password: '',
    repeatPassword: '',
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const validUntil = addMinutes(1);

  const handleSubmit = debounce(async resend => {
    if (values?.password?.length < 7) {
      setPasswordError('Password must be longer than or equal to 7 characters');

      return;
    } else {
      setPasswordError('')
    }

    if (values?.password === values?.oldPassword && passwordStatus) {
      setPasswordError('The new password must not be equal to the old one');

      return;
    } else {
      setPasswordError('')
    }

    try {
      const response = await setPasswordSignature(merchantId, walletId, {
        oldPassword: values?.oldPassword,
        password: values?.password,
        repeatPassword: values?.repeatPassword,
        emailOtp: emailTwoFa,
        twoFactorToken: twoFactorToken,
        verifyCode: twoVerifyCode,
      }).then(res => {
        if (res.status === true) {
          setEmailTwoFa('');
          setTwoFactorToken('');
          setTwoVerifyCode('');
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, 1000);

  const handleDisableResend = () => {
    const timeout = setTimeout(() => {
      setDisableResend(false);
    }, 60000);

    return () => clearTimeout(timeout);
  };

  const resendVerifyCode = async () => {
    try {
      if (downloadAutosignStep === 2) {
        await handleDownloadAutosign(true)
      } else {
        await handleSubmit(true);
      }
      setDisableResend(true);
      handleDisableResend();
    } catch (error) {
      console.log('error', error);
    }
  };

  const closeModal = () => {
    setStep(0);
    setValues({
      oldPassword: '',
      password: '',
      repeatPassword: '',
    });
    setTwoVerifyCode('');
    setEmailTwoFa('');
    setTwoFactorToken('');
    form.resetFields();
    onCancel();
    setPasswordError('');
    dispatch(resetAutosignStep());
    dispatch(resetDownloadAutosignStep());
  };

  useEffect(() => {
    if (isOpen) {
      setEmailTwoFa('');
      setTwoFactorToken('');
      setTwoVerifyCode('');
    }
  }, [isOpen])

  const handleDownloadAutosign = debounce(async () => {
    try {
      await downloadAutosign(merchantId, walletId, {
        twoFactorToken: twoFactorToken || undefined,
        emailOtp: emailTwoFa || undefined,
        password: password || values?.password || undefined,
        verifyCode: twoVerifyCode || undefined,
      });
    } catch (error) {
      console.error(error);
    }
  }, 1000);

  useEffect(() => {
    if ((autosignStep === 4 && downloadAutosignStep === 1) || (autosignStep === 4 && downloadAutosignStep === 2)) {
      setEmailTwoFa('');
      setTwoFactorToken('');
      setTwoVerifyCode('');
      dispatch(resetAutosignStep());
      form.resetFields();
    }

    if (downloadAutosignStep === 4) {
      onCancel();
      setPasswordError('');
      dispatch(resetAutosignStep());
      dispatch(resetDownloadAutosignStep());
    }
  }, [downloadAutosignStep]);

  useEffect(() => {
    if (autosignStep === 1) {
      setEmailTwoFa('');
      setTwoFactorToken('');
      setTwoVerifyCode('');
      form.resetFields();
    }
  }, [autosignStep])

  return (
    <Modal
      title={downloadAutosignStep === 1 || downloadAutosignStep === 2 || downloadAutosignStep === 3 ? null : autosignStep !== 4 || downloadAutosignStep !== 4 ? t('signPassword.setUp') : null}
      visible={isOpen}
      onCancel={closeModal}
      width={369}
      footer={null}
      className={`modal modal-${identity}`}
    >
      <div className={`sign-password-modal ${autosignStep === 4 && 'sign-password-modal__header'} ${(downloadAutosignStep === 1 || downloadAutosignStep === 2 || downloadAutosignStep === 3) && 'sign-password-modal__padding'}`}>
        <Form
          form={form}
          className="form"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (downloadAutosignStep === 0 && autosignStep !== 4) {
                handleSubmit(false);
              }
              if (autosignStep === 0 && downloadAutosignStep !== 4 && downloadAutosignStep !== 0) {
                handleDownloadAutosign();
              }
            }
          }}>
          {autosignStep === 0 && downloadAutosignStep === 0 && (
            <div className="sign-password">
              {passwordStatus && (
                <Form.Item label={t("signPassword.oldPassword")} name="oldPassword" className="form__item">
                  <Input.Password
                    className="form__input sign-password__input"
                    placeholder={t("signPassword.oldPassword")}
                    onChange={e => setValues({ ...values, oldPassword: e.target.value })}
                  />
                </Form.Item>
              )}

              <Form.Item label={t("signPassword.password")} name="password" className="form__item">
                <Input.Password
                  className="form__input sign-password__input"
                  placeholder={t("signPassword.password")}
                  onChange={e => setValues({ ...values, password: e.target.value })}
                />
              </Form.Item>

              <div className="sign-password-modal__error">{passwordError}</div>

              <Form.Item label={t("signPassword.confirmPassword")} name="repeatPassword" className="form__item">
                <Input.Password
                  className="form__input sign-password__input"
                  placeholder={t("signPassword.confirmPassword")}
                  onChange={e => setValues({ ...values, repeatPassword: e.target.value })}
                />
              </Form.Item>

              <div className="sign-password-modal__warning">* Password must be longer than or equal to 7 characters
              </div>
              {!passwordStatus && (
                <div className="sign-password-modal__warning">* The password cannot be restored. If you lose the
                  password, you will lose access to the wallet.
                </div>
              )}
              {passwordStatus &&
                <div className="sign-password-modal__warning__bottom">* The new password must not be equal to the old
                  one</div>}
            </div>
          )}
          {(autosignStep === 1 || downloadAutosignStep === 1) && (
            <div>
            <Form.Item
                label={t('auth.сodeFromSms')}
                name="verifyCode"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.phoneTwoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.сodeFromSms')}
                  className="modal-form__input"
                  maxLength="6"
                  value={twoVerifyCode}
                  onChange={e => setTwoVerifyCode(e.target.value)}
                />
              </Form.Item>
            </div>
          )}

          {(autosignStep === 2 || downloadAutosignStep === 2) && (
            <div>
              <Form.Item
                label={t('auth.keyFromEmail')}
                name="emailTwoFaCode"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.emailTwoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.keyFromEmail')}
                  className="modal-form__input"
                  maxLength="6"
                  value={emailTwoFa}
                  onChange={e => setEmailTwoFa(e.target.value)}
                />
              </Form.Item>
              <div className="resend-email-twoFa">
                <span>{t('doNotReveiveCode')}</span>
                {disableResend ? (
                  <span className="resend-email-twoFa__timer">
                    {' '}
                    Receive in <Timer validUntil={validUntil} onExpire={() => setDisableResend(false)} />
                  </span>
                ) : (
                  <span
                    className={`resend-email-twoFa__button resend-email-twoFa__button-${identity}`}
                    onClick={resendVerifyCode}
                  >
                    {' '}
                    {t('resend')}
                  </span>
                )}
              </div>
            </div>
          )}

          {(autosignStep === 3 || downloadAutosignStep === 3) && (
            <div>
              <Form.Item
                label={t('auth.keyFromGA')}
                name="twoFactorToken"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.twoFaRequired') }]}
              >
                <Input
                  className="modal-form__input"
                  placeholder={t('auth.keyFromGA')}
                  maxLength="6"
                  value={twoFactorToken}
                  onChange={e => setTwoFactorToken(e.target.value)}
                />
              </Form.Item>
            </div>
          )}

          {(autosignStep === 4 || downloadAutosignStep === 4) && (
            <div className="success-modal">
              <SuccessIcon />
              <div className={`success-modal__text success-modal__text-${identity}`}>Success!</div>
              <div
                className="success-modal__download"
                onClick={() =>
                  downloadAutosign(merchantId, walletId, { password: password || values?.password })
                }
              >
                <DownloadIcon />
                <span>Download autosign</span>
              </div>
            </div>
          )}

          <div className="sign-password__buttons">
            {(autosignStep === 0 || downloadAutosignStep === 0) && (
              <Button type="secondary" className="form__button sign-password__btn" onClick={closeModal}>
                {t('cancel')}
              </Button>
            )}
            {downloadAutosignStep === 0 && autosignStep !== 4 && (
              <Button
                onClick={() => handleSubmit(false)}
                type="primary"
                className="form__button sign-password__btn"
                disabled={
                  (autosignStatus
                    ? !values?.password || !values?.repeatPassword || values?.password !== values?.repeatPassword
                    : !values?.password ||
                      !values?.repeatPassword ||
                      !values?.oldPassword ||
                      values?.password !== values?.repeatPassword)
                  || (autosignStep === 1 && twoVerifyCode?.length < 6)
                  || (autosignStep === 2 && emailTwoFa?.length < 6)
                  || (autosignStep === 3 && twoFactorToken?.length < 6)
                }
              >
                {autosignStep === 0 ? t('signPassword.create') : t('signPassword.verify')}
              </Button>
            )}
            {autosignStep === 0 && downloadAutosignStep !== 4 && downloadAutosignStep !== 0 && (
              <Button
                onClick={() => handleDownloadAutosign()}
                type="primary"
                className="form__button sign-password__btn"
                disabled={
                  (downloadAutosignStep === 1 && twoVerifyCode?.length < 6)
                  || (downloadAutosignStep === 2 && emailTwoFa?.length < 6)
                  || (downloadAutosignStep === 3 && twoFactorToken?.length < 6)
                }
              >
                {t('signPassword.verify')}
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default SignPasswordModal;
